import "../../App.css";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SuccessImage from "../../images/success.png";
import { useTranslation } from "react-i18next";
import { BsArrowLeft } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import configData from "./../../config.js";
import { Button } from "@mui/material";
import moment from "moment";
import { openDatabase, getAddMember, createAddMember } from "../db.js";

function AddMember(props) {
  const { t } = useTranslation();
  const [model, setModel] = useState(false);
  const [memberAdded, setMemberAdded] = useState(false);
  const [email, setEmail] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState("44");
  const [mobileNumber, setMobileNumber] = useState("");
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState(0);
  const [, setAddress] = useState("");
  const [town, setTown] = useState("");
  const [postCode, setPostCode] = useState("");
  const [cardNumberValidation, setCardNumberValidation] = useState(false);
  const [lastNameValidation, setLastNameValidation] = useState(false);
  const [, setMembers] = useState([]);
  const [firstNameError, setFirstNameError] = useState("");
  const [, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [dobError, setDobError] = useState("");
  const [memberCardNumberError, setMemberCardNumberError] = useState("");
  const [countryCodeError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [offlineSuccessMessage, setOfflineSuccessMessage] = useState("");
  const [flatNumber, setFlatNumber] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [area, setArea] = useState("");
  const [county, setCounty] = useState("");
  const [addrCountryCode, setAddrCountryCode] = useState("");
  const [flatNumberError] = useState("");
  const [areaError] = useState("");
  const [countyError] = useState("");
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const loginMemberId = localStorage.getItem("memberId");
  const [postAreaList, setPostAreaList] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isUnknown, setIsUnknown] = useState();

  let maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 12);

  let minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 100);

  useEffect(() => {
    setModel(props.showModel);
    setMemberAdded(!props.showModel);
  }, [props.showModel]);

  useEffect(() => {
    if (props.activeUsers) {
      setUsers(props.activeUsers);
    } else {
      setUsers([]);
    }
  }, [props.activeUsers]);

  const handleStoringData = () => {
    const data = {
      FirstName: firstName,
      LastName: lastName,
      DateOfBirth: dob,
      Gender: gender,
      IssuerTerritory: 0,
      MemberPhysicalCardNumber: cardNumber,
      EmailAddress: email,
      MobileNumber: mobileNumber,
      MobileCountryCode: countryCode,
      PostAddr0: flatNumber,
      Postcode: postCode,
      PostAddr1: streetNumber,
      PostAddr2: area,
      AddrCounty: county,
      PostAddrTown: town,
      PostcodeCountry: addrCountryCode,
      CreatedBy: loginMemberId,
      cardNumberValidation: cardNumberValidation,
      emailError: emailError,
      memberCardNumberError: memberCardNumberError,
      mobileNumberError: mobileNumberError,
    };
    if (!localStorage.getItem("addNewMember")) {
      localStorage.setItem("addNewMember", JSON.stringify(data));
    }
    setModel(false);
  };

  useEffect(() => {
    if (memberAdded) {
      setTimeout(() => {
        setMemberAdded(false);
        setModel(false);
        handleResetFields();
      }, 1000);
    }
  }, [memberAdded]);

  useEffect(() => {
    if (isUnknown && cardNumber.length > 1) {
      setEmail(`${cardNumber}@ceroc-member-without-email.co.uk`);
    }
    if (isUnknown === false) {
      setEmail("");
    }
  }, [isUnknown, cardNumber]);

  const handleFetchingData = () => {
    const data = JSON.parse(localStorage.getItem("addNewMember"));
    if (data) {
      setCardNumberValidation(data?.cardNumberValidation);
      setFirstName(data.FirstName);
      setLastName(data.LastName);
      setDob(data.DateOfBirth);
      setCountryCode(data.MobileCountryCode ?? "44");
      setPostCode(data.postCode);
      setTown(data.PostAddrTown);
      setMobileNumber(data.MobileNumber);
      setEmail(data.EmailAddress);
      setCardNumber(data.MemberPhysicalCardNumber);
      setGender(data.Gender);
      setEmailError(data.emailError);
      setMobileNumberError(data.mobileNumberError);
      setMemberCardNumberError(data.memberCardNumberError);
      setFlatNumber(data.PostAddr0);
      setStreetNumber(data.PostAddr1);
      setArea(data.PostAddr2);
      setCounty(data.AddrCounty);
      setAddrCountryCode(data.PostcodeCountry);
    }
    localStorage.removeItem("addNewMember");
  };

  const handleResetFields = () => {
    setCardNumberValidation(false);
    setLastNameValidation(false);
    setErrorMessage("");
    setCardNumberValidation("");
    setFirstName("");
    setLastName("");
    setDob("");
    setCountryCode("44");
    setPostCode("");
    setTown("");
    setMobileNumber("");
    setEmail("");
    setCardNumber("");
    setGender("");
    setEmailError("");
    setMobileNumberError("");
    setMemberCardNumberError("");
    setFlatNumber("");
    setStreetNumber("");
    setArea("");
    setCounty("");
    setAddrCountryCode("");
    localStorage.removeItem("addNewMember");
  };

  const fetchPostDetails = (code) => {
    fetch(`${configData.MEMBER_URL}Members/postcodelookup/${code}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 404) {
          setPostAreaList([]);
        }

        if (data.result) {
          const postList = data.result ? data.result.map((e) => e.addr1) : [];
          setPostAreaList([...new Set(postList)]);
          const postCodeData = data.result[0];
          setFlatNumber(postCodeData.addr0);
          setStreetNumber(postCodeData.addr1);
          setArea(postCodeData.addr2);
          setAddrCountryCode(postCodeData.addrCountryCode);
          setTown(postCodeData.addrTown);
        }
      });
  };

  const handlePostCodeChange = (e) => {
    setPostCode(e.target.value);
    if (e.target.value && navigator.onLine) {
      fetchPostDetails(e.target.value);
    }
  };

  const formattedDate = (date) => {
    let newDate = new Date(date);
    let mnth = ("0" + (newDate.getMonth() + 1)).slice(-2);
    let day = ("0" + newDate.getDate()).slice(-2);
    return [newDate.getFullYear(), mnth, day].join("-");
  };

  const handleSubmitOffline = (e) => {
    e.preventDefault();
    const genderValue = gender === "Male" ? 1 : gender === "Female" ? 2 : 0;
    const countryCodeValue = countryCode ? countryCode.replace(/\D/g, "") : 0;
    let flag = 0;
    let dobValue = dob ? formattedDate(dob) : null;
    let maxDate = new Date(new Date().toDateString());
    maxDate.setFullYear(maxDate.getFullYear() - 12);
    setLastNameError("");
    setMemberCardNumberError("");
    setEmailError("");
    setMobileNumberError("");
    setErrorMessage("");

    setCardNumberValidation(false);
    setLastNameValidation(false);

    if (!cardNumber || !lastName) {
      if (!cardNumber) {
        setCardNumberValidation(true);
      }
      if (!lastName) {
        setLastNameValidation(true);
      }
      return;
    }
    if (!cardNumberValidation && cardNumber && cardNumber.length < 4) {
      setMemberCardNumberError(`${t("Invalid member card number")}`);
      return;
    }

    users.map((e) => {
      if (e.memberCardNumber === cardNumber) {
        setMemberCardNumberError(`${t("Member card number already in use")}`);
        flag = 1;
        return;
      }
      if (email) {
        if (e.emailId === email) {
          setEmailError(`${t("Email Id already in use")}`);
          flag = 1;
          return;
        }
      }
    });

    if (!flag) {
      let requestsArray = [];
      let requestObject;
      openDatabase().then((db) => {
        getAddMember(db, 1).then((data) => {
          requestObject = data;

          // if the object doesn't exist, create it
          if (!requestObject) {
            requestObject = { createMemberRequests: [], id: 1 };
          } else {
            // otherwise, get the existing requests array
            requestsArray = requestObject.createMemberRequests;
          }

          // add each request to the array
          requestsArray.push({
            FirstName: firstName,
            LastName: lastName,
            DateOfBirth: dobValue ? moment(dobValue).format("YYYY-MM-DD") : "",
            Gender: genderValue,
            IssuerTerritory: 0,
            MemberPhysicalCardNumber: cardNumber,
            EmailAddress: email,
            MobileNumber: mobileNumber,
            MobileCountryCode:
              parseInt(countryCodeValue) !== 0
                ? parseInt(countryCodeValue)
                : 91,
            PostAddr0: flatNumber,
            Postcode: postCode,
            PostAddr1: streetNumber,
            PostAddr2: area,
            AddrCounty: county,
            PostAddrTown: town,
            PostcodeCountry: addrCountryCode,
            CreatedBy: loginMemberId,
          });

          //update the object in object store
          requestObject.createMemberRequests = requestsArray;

          createAddMember(db, requestObject);

          props.addMemberOfflineDataCallback(true);
          setOfflineSuccessMessage("Member data stored in offline mode");
          setCardNumber("");
          setFirstName("");
          setLastName("");
          setEmail("");
          setMobileNumber("");
          setDob("");
          setGender(0);
          setPostCode("");
          setAddress("");
          setTown("");
          setFlatNumber("");
          setStreetNumber("");
          setArea("");
          setCounty("");
          setAddrCountryCode("");
          setTimeout(() => {
            setOfflineSuccessMessage("");
          }, 3000);
        });
      });
    }
  };

  function validateEmailReg(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  function validateDate(dateString) {
    const re = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    return re.test(dateString);
  }

  const handleSubmit = async (e) => {
    const genderValue = gender === "Male" ? 1 : gender === "Female" ? 2 : 0;
    const countryCodeValue = countryCode ? countryCode.replace(/\D/g, "") : 0;
    let dobValue = dob ? formattedDate(dob) : null;
    let maxDate = new Date(new Date().toDateString());
    maxDate.setFullYear(maxDate.getFullYear() - 12);

    setLastNameError("");
    setMemberCardNumberError("");
    setEmailError("");
    setMobileNumberError("");
    setErrorMessage("");
    setDobError("");

    setCardNumberValidation(false);
    setLastNameValidation(false);

    if (!cardNumber || !lastName || !firstName || !email) {
      if (!cardNumber) {
        setCardNumberValidation(true);
      }
      if (!firstName) {
        setFirstNameError("First Name is required!");
      }
      if (!lastName) {
        setLastNameValidation(true);
      }
      if (!email) {
        setEmailError("Email is required!");
      }
      return;
    }

    if (!cardNumberValidation && cardNumber && cardNumber.length < 4) {
      setMemberCardNumberError(`${t("Invalid member card number")}`);
      return;
    }
    if (!validateEmailReg(email)) {
      setEmailError("Invalid Email!");
      return;
    }
    if (!validateDate(dob)) {
      setDobError("Invalid Date!");
    }

    const memberData = {
      createMemberRequests: [
        {
          FirstName: firstName,
          LastName: lastName,
          DateOfBirth: dobValue ? moment(dobValue).format("YYYY-MM-DD") : "",
          Gender: genderValue,
          IssuerTerritory: 0,
          MemberPhysicalCardNumber: cardNumber,
          EmailAddress: email,
          MobileNumber: mobileNumber,
          MobileCountryCode:
            parseInt(countryCodeValue) !== 0 ? parseInt(countryCodeValue) : 91,
          PostAddr0: flatNumber,
          Postcode: postCode,
          // PostCodeCountry: "",
          PostAddr1: streetNumber,
          PostAddr2: area,
          AddrCounty: county,
          PostAddrTown: town,
          PostcodeCountry: addrCountryCode,
          CreatedBy: loginMemberId,
        },
      ],
    };

    e.preventDefault();

    fetch(`${configData.VENUE_URL}Member/createmember`, {
      method: "POST",
      body: JSON.stringify(memberData),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 401) {
          navigate("/");
        }
        if (data.statusCode === 200) {
          setMembers((members) => [memberData, ...members]);
          setMemberAdded(true);
        }
        if (
          data.statusCode === 500 &&
          data.errorMessage === "MemberCard Number already exist"
        ) {
          setMemberCardNumberError("Member Card Number already in use");
        }
        if (data.status === 422) {
          data.validationErrors.map((error) => {
            switch (error.name) {
              case "CreateMemberRequests[0].LastName":
                return setLastNameError(error.reason);
              case "CreateMemberRequests[0].EmailAddress":
                return setEmailError(error.reason);
              case "CreateMemberRequests[0].MobileNumber":
                return setMobileNumberError("Mobile Number already in use");
              default:
                return "";
            }
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    if (props.showModel) {
      handleFetchingData();
    } else {
      handleStoringData();
    }
  }, [props.showModel]);

  const handleCardNumberInput = async (e) => {
    setCardNumber(e.target.value);
    if (e.target.value && e.target.value.length > 3) {
      setMemberCardNumberError("");
      if (props.mode === "online") {
        const res = await fetch(
          `${configData.BOOKING_URL}MemberCards/verifyMemberCard?memberCardNumber=${e.target.value}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data?.result?.exists) {
          setMemberCardNumberError("Member Card Number already exists.");
        } else {
          setMemberCardNumberError("");
        }
      } else {
        let flag = 1;
        users.map((item) => {
          if (item.memberCardNumber === e.target.value) {
            flag = 0;
            setMemberCardNumberError("Member card number already in use");
            return;
          }
        });
        if (flag) {
          setMemberCardNumberError("");
        }
      }
    } else {
      setMemberCardNumberError(
        "Member Card Number should be atleast 4 digits long."
      );
    }
  };

  const validateEmail = () => {
    if (email.length > 3 && email.includes("@")) {
      let flag = 1;
      users.map((item) => {
        if (item.emailId === email) {
          flag = 0;
          setEmailError("Email address already in use");
          return;
        }
      });
      if (flag) {
        setEmailError("");
      }
    } else {
      setEmailError("Enter a valid email");
    }
  };

  const validateNumber = () => {
    if (mobileNumber.length === 10) {
      let flag = 1;
      users.map((item) => {
        if (
          item.mobileNumber.substring(item.mobileNumber.length - 10) ===
          mobileNumber
        ) {
          flag = 0;
          setMobileNumberError("Mobile number already in use");
          return;
        }
      });
      if (flag) {
        setMobileNumberError("");
      }
    } else {
      setMobileNumberError("Enter a valid number");
    }
  };

  useEffect(() => {
    if (
      !cardNumber ||
      !lastName ||
      !firstName ||
      !email ||
      !mobileNumber ||
      !dob ||
      memberCardNumberError.length > 0
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [
    cardNumber,
    lastName,
    firstName,
    email,
    mobileNumber,
    dob,
    memberCardNumberError,
  ]);

  const handleDate = (e) => {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    let len = input.value.length;

    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (len === 2) {
      input.value += "/";
    }

    if (len === 5) {
      input.value += "/";
    }
  };

  return (
    <div>
      <Modal show={model} onHide={handleStoringData}>
        <Modal.Body>
          <div
            className={
              memberAdded
                ? "member-form d-flex"
                : "member-form d-flex align-items-center"
            }
          >
            <div className="container">
              <h4 className="m-3">
                <BsArrowLeft onClick={handleStoringData} />
                {memberAdded ? " Success" : `${t("Add Member")}`}
              </h4>
              {memberAdded ? (
                <div style={{ height: "80vh" }} className="text-center p-5">
                  <img width="50%" src={SuccessImage} alt="" />
                  <div className="mt-3 bg-light rounded-4 m-auto w-75">
                    <h5 className="m-" style={{ color: "#54aa1b" }}>
                      Congratulations!
                    </h5>
                    <p className="">
                      New member added in the
                      <br />
                      <span>Ceroc Organisation</span>
                      <br />
                      <span className="fw-bold">
                        Membership No. {cardNumber}
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                <div className={memberAdded ? "row content-center" : "row"}>
                  <div className="col-lg-10 col-xl-11 mx-auto">
                    <form>
                      <span className="text-danger mb-2">{errorMessage}</span>
                      <h5 className="text-success mb-2">
                        {offlineSuccessMessage}
                      </h5>
                      <div style={{ display: "flex" }}>
                        <div
                          className="form-floating mb-2"
                          style={{ flex: "3" }}
                        >
                          <input
                            type="text"
                            id="memberCardNumber"
                            className="form-control"
                            value={cardNumber}
                            onChange={(e) => handleCardNumberInput(e)}
                            placeholder="Member Card Number"
                            data-1p-ignore="true"
                          />
                          <label htmlFor="floatingInput">
                            {t("Member Card Number")}
                            <span className="text-danger">*</span>
                          </label>
                          {cardNumberValidation && (
                            <div className="mb-2">
                              <span className="text-danger">
                                Member Card Number is required
                              </span>
                            </div>
                          )}
                          {memberCardNumberError && (
                            <div className="mb-2">
                              <span className="text-danger">
                                {memberCardNumberError}
                              </span>
                            </div>
                          )}
                        </div>
                        <div style={{ marginLeft: "5px" }}>
                          <Button
                            sx={{
                              textTransform: "none",
                              borderRadius: "2rem",
                              color: "#fff",
                              fontWeight: "915",
                              backgroundColor: "#FF2400",
                              "&:hover": {
                                backgroundColor: "#FF2400",
                              },
                            }}
                            variant="contained"
                            onClick={(e) => handleResetFields(e)}
                          >
                            Discard
                          </Button>
                        </div>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          placeholder="First Name"
                        />
                        <label htmlFor="floatingInput">
                          {t("First Name")}
                          <span className="text-danger">*</span>
                        </label>
                        {firstNameError && (
                          <div className="mb-2">
                            <span className="text-danger mb-2">
                              {firstNameError}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          placeholder="First Name"
                        />
                        <label htmlFor="floatingInput">
                          {t("Last Name")}
                          <span className="text-danger">*</span>
                        </label>
                        {lastNameValidation && (
                          <div className="mb-2">
                            <span className="text-danger">
                              {t("Last Name is required")}
                            </span>
                          </div>
                        )}
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          className="form-floating mb-2"
                          style={{ flex: "3" }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlurCapture={() => validateEmail()}
                            placeholder="Email"
                          />
                          <label htmlFor="floatingInput">
                            {t("Email")}
                            <span className="text-danger">*</span>
                          </label>
                          {emailError && (
                            <div className="mb-2">
                              <span className="text-danger mb-2">
                                {emailError}
                              </span>
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "end",
                            marginLeft: "4px",
                          }}
                        >
                          <span style={{ fontSize: "14px" }}>Unknown</span>
                          <input
                            value={isUnknown}
                            disabled={cardNumber?.length < 3}
                            onClick={(e) => {
                              setIsUnknown(e.target.checked);
                            }}
                            style={{ height: "20px", width: "20px" }}
                            type="checkbox"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-floating col-md-4 mb-2 pr-1">
                          <PhoneInput
                            country={"uk"}
                            className="mt-2"
                            specialLabel={t("Country Code")}
                            value={countryCode}
                            onChange={(countryCode) =>
                              setCountryCode(countryCode)
                            }
                          />
                          {countryCodeError && (
                            <div className="mb-2">
                              <span className="text-danger">
                                {countryCodeError}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="form-floating col-md-8">
                          <input
                            type="text"
                            value={mobileNumber}
                            onChange={(e) =>
                              setMobileNumber(
                                e.target.value.replace(
                                  new RegExp(/[^\d]/, "ig"),
                                  ""
                                )
                              )
                            }
                            onBlurCapture={() => validateNumber()}
                            maxLength={10}
                            placeholder="Mobile Number"
                            className="form-control mt-2"
                          />
                          <label htmlFor="floatingInput">
                            {t("Mobile Number")}
                          </label>
                        </div>
                      </div>
                      {mobileNumberError && (
                        <div className="mb-2">
                          <span className="text-danger">
                            {mobileNumberError}
                          </span>
                        </div>
                      )}
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          maxLength="10"
                          placeHolder="dd/mm/yyyy"
                          onKeyPress={handleDate}
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                          placeholder="Date of Birth"
                        />
                        <label htmlFor="floatingInput">
                          {t("Date of Birth")}
                        </label>
                        {dobError && (
                          <div className="mb-2">
                            <span className="text-danger mb-2">{dobError}</span>
                          </div>
                        )}
                      </div>

                      <div className="form-floating mb-2">
                        <select
                          className="form-select mt-2"
                          aria-label="Floating label select example"
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option defaultValue="0">{t("Select Gender")}</option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                          <option value="3">Other</option>
                          <option value="4">Unknown</option>
                        </select>
                        <label htmlFor="floatingSelect">{t("Gender")}</label>
                      </div>
                      <div className="form-floating mb-2">
                        <select
                          className="form-select mt-2"
                          aria-label="Floating label select example"
                          value={addrCountryCode ?? "UK"}
                          onChange={(e) => setAddrCountryCode(e.target.value)}
                        >
                          <option value="UK">UK- United Kingdom</option>
                          <option value="IE">IE- Ireland</option>
                          <option value="FR">FR - France</option>
                          <option value="NL">NL - Netherlands </option>
                        </select>
                        <label htmlFor="floatingSelect">
                          {"Address Country Code"}
                        </label>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => handlePostCodeChange(e)}
                          value={postCode}
                          placeholder="Address"
                        />
                        <label htmlFor="floatingInput">{t("Post Code")}</label>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setFlatNumber(e.target.value)}
                          value={flatNumber}
                          placeholder="Flat/Building"
                        />
                        <label htmlFor="floatingInput">
                          {t("Flat/Building")}
                        </label>
                        {flatNumberError && (
                          <div className="mb-2">
                            <span className="text-danger">
                              {flatNumberError}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="form-floating mb-2">
                        <select
                          className="form-select mt-2"
                          aria-label="Floating label select example"
                          value={streetNumber}
                          onChange={(e) => setStreetNumber(e.target.value)}
                        >
                          {postAreaList &&
                            postAreaList.map((e, i) => (
                              <option key={i} value={e}>
                                {e}
                              </option>
                            ))}
                        </select>
                        <label htmlFor="floatingSelect">
                          {"Number & Street"}
                        </label>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setArea(e.target.value)}
                          value={area}
                          placeholder="Area"
                        />
                        <label htmlFor="floatingInput">{t("Area")}</label>
                        {areaError && (
                          <div className="mb-2">
                            <span className="text-danger">{areaError}</span>
                          </div>
                        )}
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setTown(e.target.value)}
                          value={town}
                          placeholder="Address"
                        />
                        <label htmlFor="floatingInput">{t("Town")}</label>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setCounty(e.target.value)}
                          value={county}
                          placeholder="County"
                        />
                        <label htmlFor="floatingInput">{t("County")}</label>
                        {countyError && (
                          <div className="mb-2">
                            <span className="text-danger">{countyError}</span>
                          </div>
                        )}
                      </div>
                      <button
                        style={{
                          position: "sticky",
                          right: 0,
                          bottom: "0.5rem",
                        }}
                        type="button"
                        className={`btn ${
                          isFormValid ? "background-green" : "background-red"
                        } width-100 form-control p-3 text-white`}
                        // disabled={!isFormValid}
                        onClick={(e) => {
                          e.preventDefault();
                          if (isFormValid) {
                            if (navigator.onLine) {
                              handleSubmit(e);
                            } else {
                              handleSubmitOffline(e);
                            }
                          }
                        }}
                      >
                        {t("Submit")}
                      </button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddMember;

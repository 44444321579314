export function openDatabase(
  dbName = "MyDatabase",
  version = 1,
  onUpgradeNeededCallback = null
) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, version);

    request.onsuccess = (event) => {
      const db = event.target.result;
      console.log("Database opened successfully:", db);
      resolve(db);
    };

    request.onerror = (event) => {
      console.error(`Failed to open database '${dbName}':`, event.target.error);
      reject(event.target.error);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      console.log(`Database '${dbName}' upgrade needed to version ${version}.`);
      if (typeof onUpgradeNeededCallback === "function") {
        onUpgradeNeededCallback(db, event);
      }
    };
  });
}

export async function doesDatabaseExist(dbName = "MyDatabase") {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = (event) => {
      // If we can successfully open the database, it exists.
      const db = event.target.result;
      db.close(); // Always close the database connection.
      resolve(true);
    };

    request.onerror = () => {
      // If opening the database fails, it likely does not exist.
      resolve(false);
    };

    request.onupgradeneeded = () => {
      // If this fires, the database is being created/upgraded,
      // which means it didn't exist beforehand.
      request.transaction.abort(); // Cancel any upgrades
      resolve(false);
    };
  });
}

export function deleteDatabase(
  dbName = "MyDatabase",
  retries = 3,
  delay = 2000
) {
  return new Promise((resolve, reject) => {
    const deleteRequest = () => {
      const request = indexedDB.deleteDatabase(dbName);

      request.onsuccess = () => {
        console.log(`Database '${dbName}' deleted successfully.`);
        resolve();
      };

      request.onerror = (event) => {
        console.error(
          `Failed to delete database '${dbName}':`,
          event.target.error
        );
        reject(event.target.error);
      };

      request.onblocked = () => {
        console.warn(
          `Deletion of database '${dbName}' is blocked. Please close other tabs or browser instances using this database.`
        );

        // Retry logic for when deletion is blocked
        if (retries > 0) {
          console.log(`Retrying... ${retries} attempts left.`);
          setTimeout(() => {
            deleteDatabase(dbName, retries - 1, delay) // Recursive retry
              .then(resolve)
              .catch(reject);
          }, delay);
        } else {
          reject(
            new Error(
              `Database '${dbName}' deletion is blocked after multiple attempts.`
            )
          );
        }
      };
    };

    deleteRequest();
  });
}

export function ensureObjectStore(db, storeName, options = { keyPath: "id" }) {
  if (!db.objectStoreNames.contains(storeName)) {
    console.log(`Creating object store '${storeName}'`);
    return db.createObjectStore(storeName, options);
  } else {
    console.log(`Object store '${storeName}' already exists.`);
    return null; // No object store was created
  }
}

async function fetchDataFromStore(db, storeName) {
  return new Promise((resolve, reject) => {
    try {
      if (!db.objectStoreNames.contains(storeName)) {
        console.log(
          `Failed to fetch from store '${storeName}', it does not exist`
        );
        return reject(
          new Error(
            `Failed to fetch from store '${storeName}', it does not exist`
          )
        );
      }

      const transaction = db.transaction(storeName, "readonly");
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.getAll();

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = () => {
        reject(new Error(`Failed to fetch data from store '${storeName}'`));
      };
    } catch (error) {
      reject(error);
    }
  });
}

async function handleFetchOperation(db, storeName) {
  try {
    console.log(`Fetching ${storeName}`);
    const data = await fetchDataFromStore(db, storeName);
    console.log(`Fetched ${storeName} data [${data.length}]:`, data);
    return data;
  } catch (error) {
    console.error(`Error fetching ${storeName}:`, error.message);
    throw error;
  }
}

async function getDataFromStore(db, storeName, key) {
  return new Promise((resolve, reject) => {
    try {
      if (!db.objectStoreNames.contains(storeName)) {
        console.log(
          `Failed to get from store '${storeName}', it does not exist`
        );
        return reject(
          new Error(
            `Failed to get from store '${storeName}', it does not exist`
          )
        );
      }

      const transaction = db.transaction(storeName, "readonly");
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.get(key);

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = () => {
        reject(new Error(`Failed to fetch data from store '${storeName}'`));
      };
    } catch (error) {
      reject(error);
    }
  });
}

async function handleGetOperation(db, storeName, key) {
  try {
    console.log(`Getting ${storeName}:`, key);
    const data = await getDataFromStore(db, storeName, key);
    console.log(`${storeName} data:`, data);
    return data;
  } catch (error) {
    console.error(`Error getting ${storeName}:`, error.message);
    throw error;
  }
}

async function clearObjectStore(db, storeName) {
  return new Promise((resolve, reject) => {
    try {
      if (!db.objectStoreNames.contains(storeName)) {
        console.log(`Failed to clear store '${storeName}', it does not exist`);
        return reject(
          new Error(`Failed to clear store '${storeName}', it does not exist`)
        );
      }

      const transaction = db.transaction(storeName, "readwrite");
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.clear();

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = () => {
        reject(new Error(`Failed to clear store '${storeName}'`));
      };
    } catch (error) {
      reject(error);
    }
  });
}

async function handleClearOperation(db, storeName) {
  try {
    console.log(`Clearing ${storeName}`);
    const data = await clearObjectStore(db, storeName);
    console.log(`${storeName} data:`, data);
    return data;
  } catch (error) {
    console.error(`Error clearing ${storeName}:`, error.message);
    //throw error;
  }
}

async function createItemInStore(db, storeName, item) {
  return new Promise((resolve, reject) => {
    try {
      const transaction = db.transaction(storeName, "readwrite");
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.put(item);

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = () => {
        reject(
          new Error(`Failed to update or add item in store '${storeName}'`)
        );
      };
    } catch (error) {
      reject(error);
    }
  });
}

async function handleCreateOperation(db, storeName, item) {
  try {
    console.log(`Creating ${storeName}:`, item);
    const data = await createItemInStore(db, storeName, item);
    console.log(`${storeName}:`, data);
    return data;
  } catch (error) {
    console.error(`Error creating ${storeName}:`, error.message);
    //throw error;
  }
}

// Clear

export async function clearActiveUsers(db) {
  const storeName = `ActiveUsers`;
  return handleClearOperation(db, storeName);
}

export async function clearAddExpenditure(db, eventID) {
  const storeName = `AddExpenditure${eventID}`;
  return handleClearOperation(db, storeName);
}

export async function clearBookingAmount(db, eventID) {
  const storeName = `BookingAmount${eventID}`;
  return handleClearOperation(db, storeName);
}

export async function clearBookedCount(db, eventID) {
  const storeName = `BookedCount${eventID}`;
  return handleClearOperation(db, storeName);
}

export async function clearCreateAttendance(db, eventID) {
  const storeName = `CreateAttendance${eventID}`;
  return handleClearOperation(db, storeName);
}

export async function clearDeleteAttendance(db, eventID) {
  const storeName = `DeleteAttendance${eventID}`;
  return handleClearOperation(db, storeName);
}

export async function clearDeleteExpenditure(db, eventID) {
  const storeName = `DeleteExpenditur${eventID}`;
  return handleClearOperation(db, storeName);
}

export async function clearEntryType(db, eventID) {
  const storeName = `EntryType${eventID}`;
  return handleClearOperation(db, storeName);
}

export async function clearEventAttendance(db, eventID) {
  const storeName = `EventAttendance${eventID}`;
  return handleClearOperation(db, storeName);
}

export async function clearEventBookings(db, eventID) {
  const storeName = `EventBookings${eventID}`;
  return handleClearOperation(db, storeName);
}

export async function clearEventInfo(db, eventID) {
  const storeName = `EventInfo${eventID}`;
  return handleClearOperation(db, storeName);
}

export async function clearExpenditure(db, eventID) {
  const storeName = `Expenditure${eventID}`;
  return handleClearOperation(db, storeName);
}

export async function clearExpenseTypes(db) {
  const storeName = `Expensetypes`;
  return handleClearOperation(db, storeName);
}

export async function clearHomeEvents(db) {
  const storeName = `HomeEvents`;
  return handleClearOperation(db, storeName);
}

export async function clearRecordAttendance(db, eventID) {
  const storeName = `RecordAttendance${eventID}`;
  return handleClearOperation(db, storeName);
}

export async function clearUpdateAttendance(db, eventID) {
  const storeName = `UpdateAttendance${eventID}`;
  return handleClearOperation(db, storeName);
}

export async function clearUpdateMember(db) {
  const storeName = `UpdateMember`;
  return handleClearOperation(db, storeName);
}

// Create

export async function createActiveUsers(db, item) {
  const storeName = `ActiveUsers`;
  return handleCreateOperation(db, storeName, item);
}

export async function createAddExpenditure(db, eventID, item) {
  const storeName = `AddExpenditure${eventID}`;
  return handleCreateOperation(db, storeName, item);
}

export async function createAddMember(db, item) {
  const storeName = `AddMember`;
  return handleCreateOperation(db, storeName, item);
}

export async function createBookingAmount(db, eventID, item) {
  const storeName = `BookingAmount${eventID}`;
  return handleCreateOperation(db, storeName, item);
}

export async function createBookedCount(db, eventID, item) {
  const storeName = `BookedCount${eventID}`;
  return handleCreateOperation(db, storeName, item);
}

export async function createCreateAttendance(db, eventID, item) {
  const storeName = `CreateAttendance${eventID}`;
  return handleCreateOperation(db, storeName, item);
}

export async function createDeleteExpenditure(db, eventID, item) {
  const storeName = `DeleteExpenditure${eventID}`;
  return handleCreateOperation(db, storeName, item);
}

export async function createEntryType(db, eventID, item) {
  const storeName = `EntryType${eventID}`;
  return handleCreateOperation(db, storeName, item);
}

export async function createExpenditure(db, eventID, item) {
  const storeName = `Expenditure${eventID}`;
  return handleCreateOperation(db, storeName, item);
}

export async function createEventAttendance(db, eventID, item) {
  const storeName = `EventAttendance${eventID}`;
  return handleCreateOperation(db, storeName, item);
}

export async function createEventBookings(db, eventID, item) {
  const storeName = `EventBookings${eventID}`;
  return handleCreateOperation(db, storeName, item);
}

export async function createEventInfo(db, eventID, item) {
  const storeName = `EventInfo${eventID}`;
  return handleCreateOperation(db, storeName, item);
}

export async function createExpenseTypes(db, item) {
  const storeName = `Expensetypes`;
  return handleCreateOperation(db, storeName, item);
}

export async function createHomeEvents(db, item) {
  const storeName = `HomeEvents`;
  return handleCreateOperation(db, storeName, item);
}

export async function createRecordAttendance(db, eventID, item) {
  const storeName = `RecordAttendance${eventID}`;
  return handleCreateOperation(db, storeName, item);
}

export async function createUpdateAttendance(db, eventID, item) {
  const storeName = `UpdateAttendance${eventID}`;
  return handleCreateOperation(db, storeName, item);
}

export async function createUpdateMember(db, item) {
  const storeName = `UpdateMember`;
  return handleCreateOperation(db, storeName, item);
}

// Fetch

export async function fetchActiveUsers(db) {
  const storeName = `ActiveUsers`;
  return handleFetchOperation(db, storeName);
}

export async function fetchAddExpenditure(db, eventID) {
  const storeName = `AddExpenditure${eventID}`;
  return handleFetchOperation(db, storeName);
}

export async function fetchAddMember(db) {
  const storeName = `AddMember`;
  return handleFetchOperation(db, storeName);
}

export async function fetchBookingAmount(db, eventID) {
  const storeName = `BookingAmount${eventID}`;
  return handleFetchOperation(db, storeName);
}

export async function fetchBookedCount(db, eventID) {
  const storeName = `BookedCount${eventID}`;
  return handleFetchOperation(db, storeName);
}

export async function fetchCreateAttendance(db, eventID) {
  const storeName = `CreateAttendance${eventID}`;
  return handleFetchOperation(db, storeName);
}

export async function fetchDeleteAttendance(db, eventID) {
  const storeName = `DeleteAttendance${eventID}`;
  return handleFetchOperation(db, storeName);
}

export async function fetchDeleteExpenditure(db, eventID) {
  const storeName = `DeleteExpenditure${eventID}`;
  return handleFetchOperation(db, storeName);
}

export async function fetchEntryType(db, eventID) {
  const storeName = `EntryType${eventID}`;
  return handleFetchOperation(db, storeName);
}

export async function fetchEventAttendance(db, eventID) {
  const storeName = `EventAttendance${eventID}`;
  return handleFetchOperation(db, storeName);
}

export async function fetchEventBookings(db, eventID) {
  const storeName = `EventBookings${eventID}`;
  return handleFetchOperation(db, storeName);
}

export async function fetchEventInfo(db, eventID) {
  const storeName = `EventInfo${eventID}`;
  return handleFetchOperation(db, storeName);
}

export async function fetchExpenditure(db, eventID) {
  const storeName = `Expenditure${eventID}`;
  return handleFetchOperation(db, storeName);
}

export async function fetchExpenseTypes(db) {
  const storeName = `Expensetypes`;
  return handleFetchOperation(db, storeName);
}

export async function fetchHomeEvents(db) {
  const storeName = `HomeEvents`;
  return handleFetchOperation(db, storeName);
}

export async function fetchRecordAttendance(db, eventID) {
  const storeName = `RecordAttendance${eventID}`;
  return handleFetchOperation(db, storeName);
}

export async function fetchUpdateAttendance(db, eventID) {
  const storeName = `UpdateAttendance${eventID}`;
  return handleFetchOperation(db, storeName);
}

export async function fetchUpdateMember(db) {
  const storeName = `UpdateMember`;
  return handleFetchOperation(db, storeName);
}

// Get

export async function getAddMember(db, key) {
  const storeName = `AddMember`;
  return handleGetOperation(db, storeName, key);
}

export async function getUpdateMember(db, key) {
  const storeName = `UpdateMember`;
  return handleGetOperation(db, storeName, key);
}

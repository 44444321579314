import {
  Box,
  Drawer,
  IconButton,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useState } from "react";
import { useEffect } from "react";
import SuccessImage from "../../images/success.png";
import { useTranslation } from "react-i18next";
import {
  openDatabase,
  fetchEntryType,
  createUpdateAttendance,
  clearEventAttendance,
  createEventAttendance,
  fetchEventAttendance,
  createRecordAttendance,
} from "../db.js";

const UpdateAttendanceOffline = ({
  mode,
  updateAttendance,
  setUpdateAttendance,
  attendance,
  eventID,
  attendanceList,
  setUpdateList,
  // entryTypeData
}) => {
  const [memberCardNumber, setMemberCardNumber] = useState("");
  const [, setEntryTypes] = useState([]);
  const [selectedEntryType, setSelectedEntryType] = useState("");
  const [entryFeePaid, setEntryFeePaid] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentTypes] = useState(["Card", "Cash", "Online"]);
  const [entryType, setEntryType] = useState([]);
  const loginMemberId = localStorage.getItem("memberId");
  //const [eventId, setEventId] = useState();
  //const eventId = "8dd1c12c-88ba-4ac2-ab16-7e18c151f928";
  const [memberFullName, setMemberFullName] = useState();
  const memberId = localStorage.getItem("memberId");
  const [, setPriceOfEvent] = useState(0);
  const [entryTypeError] = useState();
  const [recordAttendanceSuccess] = useState(false);
  const [apiStatus] = useState();
  const [, setAttendanceId] = useState("");
  const [updateAttendenceSuccessMessage, setUpdateAttendenceSuccessMessage] =
    useState("");
  const [canSave, setCanSave] = useState(false);
  const { t } = useTranslation();
  //for Currency Code
  const [voucherSecurityCode, setVoucherSecurityCode] = useState("");
  const [voucherNumber, setVoucherNumber] = useState("");
  const [voucherNumberError, setVoucherNumberError] = useState("");
  const [securityCodeError, setSecurityCodeError] = useState("");
  const [disablePayment, setDisablePayment] = useState(false);

  useEffect(() => {
    if (mode === "offline") {
      openDatabase().then((db) => {
        fetchEntryType(db, eventID)
          .then((data) => {
            let entryTypes = data[0];
            let tempFields = [...entryTypes];
            const temp = tempFields[2];
            tempFields[2] = tempFields[0];
            tempFields[0] = temp;
            setEntryType(tempFields);
          })
          .catch((error) => {});
      });
    }
  }, [eventID]);

  useEffect(() => {
    if (attendance) {
      setMemberCardNumber(
        attendance.memberCardNumber ?? attendance?.MemberCardNumber
      );
      setMemberFullName(
        attendance.fullName
          ? attendance.fullName
          : attendance?.firstName
          ? `${attendance?.firstName} ${attendance?.lastName}`
          : attendance?.name
          ? attendance?.name
          : ""
      );
      setPaymentMode(
        attendance?.paymentMethodName
          ? attendance?.paymentMethodName
          : attendance?.PaymentMethodName
      );
      const retrieveEntryTypeID = entryType?.filter(
        (entryTypeItem) =>
          entryTypeItem.entryTypeName === attendance.entryTypeName
      );
      let amountPending =
        retrieveEntryTypeID[0]?.entryFee - attendance.entryFeePaid;
      setPriceOfEvent(amountPending);
      setSelectedEntryType(retrieveEntryTypeID[0]?.entryTypeID);
      setAttendanceId(attendance.attendanceID);
      setEntryFeePaid(attendance.entryFeePaid ?? attendance.EntryFeePaid);
      // setEntryType(attendance.entryTypeName)
    }
  }, [attendance, entryType]);

  useEffect(() => {
    if (selectedEntryType) {
      const tempVal = entryType?.filter(
        (entryTypeItem) => entryTypeItem.entryTypeID === selectedEntryType
      );
      if (tempVal[0] && tempVal[0].entryTypeName?.includes("CREW")) {
        setDisablePayment(true);
        setPaymentMode();
      } else {
        setDisablePayment(false);
      }
    }
  }, [selectedEntryType]);

  const handleUpdateAttendance = () => {
    if (voucherNumber.length > 0 && voucherNumber.length < 4) {
      setVoucherNumberError("Invalid Voucher code");
      return;
    }
    if (voucherSecurityCode.length > 0 && voucherSecurityCode.length < 4) {
      setSecurityCodeError("Invalid Voucher code");
      return;
    }
    const selectedEntry = entryType.filter((entryTypeData) => {
      return entryTypeData.entryTypeID === selectedEntryType;
    });
    let entryFee = selectedEntry[0].entryFee;

    if (!navigator.onLine) {
      openDatabase().then((db) => {
        createUpdateAttendance(db, eventID, {
          attendanceID: attendance.attendanceID,
          entryTypeID: selectedEntry[0].entryTypeID,
          entryFeePaid: entryFee,
          memberCardNumber: parseInt(memberCardNumber),
          updatedBy: loginMemberId,
          paymentMethodName:
            paymentMode.length > 1 ? paymentMode : "No Payment",
          voucherNumber: voucherNumber,
          voucherSecurityCode: voucherSecurityCode,
        });
        let tempAttendance = attendanceList.map((obj) => {
          // Check if the object's property matches the condition
          if (obj.memberCardNumber === attendance.memberCardNumber) {
            return {
              ...obj,
              attendanceID: attendance.attendanceID,
              entryTypeID: selectedEntry[0].entryTypeID,
              entryFeePaid: entryFee,
              memberCardNumber: parseInt(memberCardNumber),
              updatedBy: loginMemberId,
              paymentMethodName:
                paymentMode.length > 1 ? paymentMode : "No Payment",
              entryTypeName: selectedEntry[0].entryTypeName,
              voucherNumber: voucherNumber,
              voucherSecurityCode: voucherSecurityCode,
            };
          }
          return obj;
        });
        clearEventAttendance(db, eventID).then(() => {
          tempAttendance.forEach(function (item, index) {
            item.id = index;
            createEventAttendance(db, eventID, item);
          });
        });
        setUpdateList(true);
        setUpdateAttendenceSuccessMessage("Offline Attendance Updated");
        setUpdateAttendance(false);
        setTimeout(() => {
          setUpdateAttendenceSuccessMessage("");
        }, 3000);
      });
      return;
    }
  };

  useEffect(() => {
    if (attendance) {
      if (
        attendance?.entryTypeName !== entryType ||
        attendance?.paymentType !== paymentMode ||
        attendance.fullName !== memberFullName
      ) {
        setCanSave(true);
      } else {
        setCanSave(false);
      }
    }
  }, [entryType, paymentMode, memberFullName]);

  const handleEntryType = (entryTypeValue) => {
    const selectedEntry = entryType.filter((entryType) => {
      return entryType.entryTypeID === entryTypeValue;
    });
    let entryFee = selectedEntry[0]?.entryFee - entryFeePaid;
    setSelectedEntryType(entryTypeValue);
    setPriceOfEvent(entryFee);
  };

  // offline data
  useEffect(() => {
    if (!navigator.onLine && attendance) {
      // For Entry Type
      openDatabase().then((db) => {
        fetchEntryType(db, eventID)
          .then((data) => {
            let entryTypes = data[0];
            let attendanceEventTypes = entryTypes?.filter(
              (entryTypeItem) =>
                entryTypeItem.entryFee >= attendance.entryFeePaid
            );
            setEntryTypes(attendanceEventTypes);
            fetchEventAttendance(db, eventID).then((data) => {
              data?.map((member) => {
                if (member.memberCardNumber === memberCardNumber) {
                  const retrieveEntryTypeID = entryTypes?.find(
                    (entryTypeItem) =>
                      entryTypeItem.entryTypeName === member.entryTypeName
                  );
                  let amountPending =
                    retrieveEntryTypeID.entryFee - member.entryFeePaid;
                  setPriceOfEvent(amountPending);
                  setSelectedEntryType(retrieveEntryTypeID);
                  setAttendanceId(member.attendanceID);
                  let selectedPaymentMode = member.paymentMethodName
                    ? member.paymentMethodName === "cerditCard"
                      ? "CreditCard"
                      : member.paymentMethodName
                    : attendance.PaymentMethodName === "cerditCard"
                    ? "CreditCard"
                    : attendance.PaymentMethodName;
                  setPaymentMode(selectedPaymentMode);
                  handleRecordAttendance(member.attendanceID);
                }
              });
            });
          })
          .catch((error) => {});
      });
    }
  }, []);

  const handleRecordAttendance = (attendanceID) => {
    if (navigator.onLine === "offline") {
      openDatabase().then((db) => {
        createRecordAttendance(db, eventID, {
          attendanceID: attendanceID,
          recordedBy: memberId,
        });
      });
      return;
    }
  };

  useEffect(() => {
    if (disablePayment) {
      setPaymentMode("No Payment");
      setVoucherNumber("");
      setVoucherSecurityCode("");
    }
  }, [disablePayment]);

  return (
    <div>
      <Drawer
        PaperProps={{
          sx: {
            width: "30%",
            height: "100vh",
          },
        }}
        anchor="right"
        open={updateAttendance}
        onClose={() => setUpdateAttendance(false)}
      >
        {!recordAttendanceSuccess ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                m: "2rem",
              }}
            >
              <Typography>
                <IconButton onClick={() => setUpdateAttendance(false)}>
                  <KeyboardBackspaceOutlinedIcon />
                </IconButton>
                {t("Edit Entry Offline mode")}
              </Typography>
              {updateAttendenceSuccessMessage ? (
                <h6
                  className={
                    updateAttendenceSuccessMessage
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  {updateAttendenceSuccessMessage
                    ? updateAttendenceSuccessMessage + " !"
                    : ""}
                </h6>
              ) : (
                ""
              )}
              {navigator.onLine ? (
                <Box>
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                    }}
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png"
                    alt="qrCode"
                  />
                </Box>
              ) : null}
              <div
                className="form-floating mb-2"
                style={{ width: "100%", flex: "3" }}
              >
                <input
                  type="text"
                  id="memberCardNumber"
                  className="form-control"
                  style={{ fontSize: "22px" }}
                  onChange={(e) => setMemberCardNumber(e.target.value)}
                  value={memberCardNumber}
                  disabled={true}
                  placeholder="Member Card Number"
                  data-1p-ignore="true"
                />
                <label htmlFor="floatingInput">{t("Member Card Number")}</label>
              </div>
              <div className="form-floating mb-2" style={{ width: "100%" }}>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setMemberFullName(e.target.value)}
                  value={memberFullName}
                  disabled={true}
                  placeholder="Full Name"
                />
                <label htmlFor="floatingInput">{t("Full Name")}</label>
              </div>
              <FormControl sx={{ width: "100%" }} size="small">
                <InputLabel id="demo-simple-select-label">
                  {t("Entry Type")}
                </InputLabel>
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 40 * 4.5 + 4,
                      },
                    },
                  }}
                  labelId="Entry Type"
                  label={t("Entry Type")}
                  value={selectedEntryType}
                  onChange={(e) => handleEntryType(e.target.value)}
                >
                  {entryType?.map((entryType) => (
                    <MenuItem
                      key={entryType.entryTypeID}
                      value={entryType.entryTypeID}
                    >
                      {entryType.entryTypeName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {entryTypeError ? (
                <Typography
                  variant="paragraph"
                  sx={{ fontSize: "12px", color: "red" }}
                >
                  {entryTypeError}
                </Typography>
              ) : null}
              <FormControl sx={{ width: "100%" }} size="small">
                <InputLabel>{t("Payment Mode")}</InputLabel>
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 40 * 4.5 + 4,
                      },
                    },
                  }}
                  labelId="Payment Mode"
                  label={"Payment Mode"}
                  value={paymentMode}
                  onChange={(e) => setPaymentMode(e.target.value)}
                  disabled={disablePayment}
                >
                  {paymentTypes?.map((paymentType) => (
                    <MenuItem key={paymentType} value={paymentType}>
                      {paymentType}
                    </MenuItem>
                  ))}
                </Select>
                <div
                  className="form-floating mt-2 mb-2"
                  style={{ width: "100%" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setVoucherNumber(e.target.value)}
                    value={voucherNumber}
                    disabled={disablePayment}
                    placeholder="Voucher Number"
                  />
                  <label htmlFor="floatingInput">{t("Voucher Number")}</label>
                  {voucherNumberError && (
                    <div className="mb-2">
                      <span className="text-danger">{voucherNumberError}</span>
                    </div>
                  )}
                </div>
                <div
                  className="form-floating mt-2 mb-2"
                  style={{ width: "100%" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setVoucherSecurityCode(e.target.value)}
                    value={voucherSecurityCode}
                    disabled={disablePayment}
                    placeholder="Security Code"
                  />
                  <label htmlFor="floatingInput">{t("Security Code")}</label>
                  {securityCodeError && (
                    <div className="mb-2">
                      <span className="text-danger">{securityCodeError}</span>
                    </div>
                  )}
                </div>
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                //alignItems: "center",
                gap: "1rem",
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  width: "40%",
                  backgroundColor: "lightgray",
                  //m: "auto",
                  color: "grey",
                  p: "0.5rem 0.5rem",
                  "&:hover": {
                    backgroundColor: "lightgray",
                  },
                }}
                onClick={() => setUpdateAttendance(false)}
              >
                {t("Cancel")}
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  width: "40%",
                  backgroundColor: "orange",
                  //m: "0 auto",
                  color: "#fff",
                  p: "0.5rem 0.5rem",
                  "&:hover": {
                    backgroundColor: "orange",
                  },
                }}
                onClick={handleUpdateAttendance}
                disabled={!canSave ? true : false}
                className={!canSave ? "not-allowed" : null}
              >
                {t("Save")}
              </Button>
            </Box>
          </>
        ) : (
          <Box>
            <Typography>
              <IconButton onClick={() => setUpdateAttendance(false)}>
                <KeyboardBackspaceOutlinedIcon />
              </IconButton>
              Success
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: "7rem",
                gap: "2rem",
              }}
            >
              <img width="50%" src={SuccessImage} alt="" />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  backgroundColor: "lightgrey",
                  padding: "0.7rem",
                }}
              >
                <Typography variant="h6" sx={{ color: "green" }}>
                  Successful!
                </Typography>
                <Typography variant="paragraph">{apiStatus}</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Drawer>
    </div>
  );
};

export default UpdateAttendanceOffline;

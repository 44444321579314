import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useTranslation } from "react-i18next";
import configData from "./../../config.js";
import moment from "moment";

function MembershipDetails() {
  const { t } = useTranslation();
  const location = useLocation();
  const findMemberID = location.state ? location.state.memberID : "";
  const membershipCardNumber = location.state
    ? location.state.membershipCard
    : "";
  //for testing purpose
  //const memberID = "26beed7f-5315-49a9-9a8b-0314d031f9f1";
  const [editModel, setEditModel] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [name, setName] = useState("");
  const [postCode, setPostCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileCountryCode, setMobileCountryCode] = useState("");
  const [dob, setDob] = useState(new Date());
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [bookings, setBookings] = useState([]);
  const [bookingCount, setBookingCount] = useState([]);
  const [member, setMember] = useState([]);
  const [memberCards, setMemberCards] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [, setTownError] = useState("");
  const [dobError, setDobError] = useState("");
  const [town, setTown] = useState("");
  const navigate = useNavigate();
  const defaultProfilePic = "./Images/defaultpp.png";
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const token = localStorage.getItem("token");

  const headCells = [
    {
      id: "memberId",
      label: `${t("Member ID")}`,
      align: "center",
    },
    {
      id: "memberCardNumber",
      label: `${t("Membership Card")}`,
      align: "center",
    },
    {
      id: "venueKnownAs",
      label: `${t("Venue")}`,
      align: "center",
    },
    {
      id: "eventTypeName",
      label: `${t("Event Type")}`,
      align: "center",
    },
    {
      id: "eventStart",
      label: `${t("Entry Date")}`,
      align: "center",
    },
    {
      id: "entryTypeName",
      label: `${t("Entry Type")}`,
      align: "center",
    },
    {
      id: "isAttended",
      label: `${t("Attendance Check In")}`,
      align: "center",
    },
  ];

  const handleModal = (data) => {
    if (!editModel) {
      let countryCode = data?.mobileCountyCode ?? null;
      let mobileNumber = data?.mobileNumber ?? null;
      if (data?.mobileNumber?.length > 10) {
        let num = data.mobileNumber.toString();
        countryCode = num.substring(0, 2);
        mobileNumber = num.substring(2);
      }
      let date = moment(data?.dateOfBirth).format("YYYY-MM-DD");
      setMemberId(data?.memberID);
      setFirstName(data?.firstName);
      setLastName(data?.lastName);
      setEmail(data?.email);
      setMobileNumber(mobileNumber);
      setMobileCountryCode(countryCode);
      setDob(date);
      setGender(data?.gender);
      setAddress(data?.address);
      setPostCode(data?.postalCode);
      setTown(data?.addrTown);
      setEditModel(true);
      setMobileNumberError("");
      setAddressError("");
      setTownError("");
      setDobError("");
    } else {
      setEditModel(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!mobileNumber || mobileNumber.length !== 10) {
      setMobileNumberError("Entered Mobile is Not Valid");
      return;
    }
    if (!dob) {
      setDobError("Please enter Date of Birth");
      return;
    }
    if (!email) {
      setEmailError("Please enter email");
      return;
    }
    if (!address || !postCode) {
      setAddressError("Address or Post code field is missing");
      return;
    }

    setEmailError("");
    setMobileNumberError("");
    setAddressError("");
    setTownError("");
    setDobError("");

    const putData = {
      MemberID: findMemberID,
      gender: parseInt(gender),
      MobileNumber: mobileNumber,
      address: address,
      AddrPostcode: postCode,
      MobileCountryCode: 91,
      postAddrTown: town,
      updatedBy: memberId,
      dateOfBirth: dob,
    };

    await fetch(`${configData.VENUE_URL}Member/UpdateMembershipDetails`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(putData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200) {
          const member = fetchMemberData(findMemberID);
          setMember(member);
          setEmail(email);
          setMobileNumber(mobileNumber);
          setEditModel(false);
        } else if (data.error === "invalid_request") {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchMemberData = async (findMemberID) => {
    const res = await fetch(
      `${configData.VENUE_URL}Member/${findMemberID}/GetMembershipDetailsByMemberId`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const fetchBookingsData = async () => {
    const res = await fetch(
      `${configData.VENUE_URL}Member/${findMemberID}/getbookings`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const fetchMemberCardsData = async () => {
    const res = await fetch(
      `${configData.VENUE_URL}Member/${findMemberID}/getmembercards`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  useEffect(() => {
    if (findMemberID) {
      fetchMemberData(findMemberID).then((data) => {
        if (data.isError) {
          navigate("/");
        }

        if (data.statusCode === 200) {
          const memberData = data.payload;
          setMember(memberData);
          let name = `${memberData.firstName} ${memberData.lastName}`;
          setName(name);
          setAddress(memberData.address);
        }
      });
    }
  }, [
    findMemberID,
    member.gender,
    member.mobileNumber,
    member.addr1,
    member.addrPostcode,
    member.addrTown,
  ]);

  useEffect(() => {
    if (findMemberID) {
      fetchBookingsData().then((data) => {
        if (data.isError) {
          navigate("/");
        }

        if (data.statusCode === 200) {
          const bookingData = data.payload.bookingHistories;
          const countDetails = data.payload.bookingHistoryCount;

          setBookings(bookingData);
          setBookingCount(countDetails);
        }
      });

      fetchMemberCardsData().then((data) => {
        if (data.isError) {
          navigate("/");
        }

        if (data.statusCode === 200) {
          const memberCardsData = data.payload;
          setMemberCards(memberCardsData);
        }
      });
    }
  }, [editModel, findMemberID]);

  const formattedDate = (date) => {
    let newDate = new Date(date);
    if (newDate.getFullYear() === 1) return;
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    let mnth = newDate.getMonth();
    let day = ("0" + newDate.getDate()).slice(-2);
    return [day, monthNames[mnth], newDate.getFullYear()].join(" ");
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  return (
    <div className="container">
      <h4 className="m-2 mt-4">{t("MEMBER DETAILS")}</h4>

      <table className="table table-responsive rounded-4 bg-light mt-3 border-white">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-3">
              <img
                width="130rem"
                height="130rem"
                alt="member"
                className="rounded-circle"
                src={
                  member.memberAvatarURL
                    ? member.memberAvatarURL
                    : defaultProfilePic
                }
              />
              <h5 className="pt-3 font-600">{name}</h5>
            </td>
            <td className="pt-4" style={{ minWidth: "15rem" }}>
              <div className="bg-white border rounded p-2 mb-2">
                <span className="opacity-50">
                  {t("Membership Card Number")}
                </span>
                <br />
                <span className="font-600">
                  {membershipCardNumber
                    ? membershipCardNumber
                    : member.membershipCard ?? "N/A"}
                </span>
              </div>
              <div className="bg-white border rounded p-2">
                <span className="opacity-50">{t("Date of Birth")}</span>
                <br />
                <span className="font-600">
                  {member.dateOfBirth
                    ? formattedDate(member.dateOfBirth)
                    : "N/A"}
                </span>
              </div>
            </td>
            <td className="pt-4" style={{ minWidth: "15rem" }}>
              <div className="bg-white border rounded p-2 mb-2">
                <span className="opacity-50">{t("Email")}</span>
                <br />
                <span className="font-600">
                  {member.email ? member.email : "N/A"}
                </span>
              </div>
              <div className="bg-white border rounded p-2">
                <span className="opacity-50">{t("Address")}</span>
                <br />
                <span className="font-600">{member.address ?? "N/A"} </span>
              </div>
            </td>
            <td className="pt-4" style={{ minWidth: "10rem" }}>
              <div className="bg-white border rounded p-2 mb-2">
                <span className="opacity-50">{t("Contact")}</span>
                <br />
                <span className="font-600">
                  {member.mobileCountyCode && member.mobileNumber
                    ? `+${member.mobileCountyCode}${member.mobileNumber}`
                    : member.mobileNumber
                    ? `+${member.mobileNumber}`
                    : "N/A"}
                </span>
              </div>
              <div className="bg-white border rounded p-2">
                <span className="opacity-50">{t("Postal Code")}</span>
                <br />
                <span className="font-600">{member.postalCode ?? "N/A"}</span>
              </div>
            </td>
            <td className="pt-4">
              <div className="bg-white border rounded p-2 mb-2">
                <span className="opacity-50">{t("Gender")}</span>
                <br />
                <span className="font-600">
                  {member.gender === 1
                    ? "Male"
                    : member.gender === 2
                    ? "Female"
                    : "N/A"}
                </span>
              </div>
            </td>
            <td style={{ minWidth: "6rem" }}>
              <button
                className="btn btn-info rounded-pill text-white"
                onClick={(e) => handleModal(member, e)}
              >
                <FaRegEdit className="mb-1" /> {t("Edit")}
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="mt-5">
        <h4>{t("BOOKINGS")}</h4>
        <table className="table rounded-4 overflow-hidden bg-light mt-3 border-white">
          <thead>
            <tr className="text-white background-orange text-center">
              <th style={{ background: "#F87B03", color: "white" }} scope="col">
                {t("Total Bookings")}
              </th>
              <th style={{ background: "#F87B03", color: "white" }} scope="col">
                {t("Event Attended")}
              </th>
              <th style={{ background: "#F87B03", color: "white" }} scope="col">
                {t("Event Missed")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td style={{ background: "#f1f1f1" }}>
                {bookingCount.totalBookings}
              </td>
              <td style={{ background: "#f1f1f1" }}>
                {bookingCount.eventsAttended}
              </td>
              <td style={{ background: "#f1f1f1" }}>
                {bookingCount.eventsNotAttended}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <TableContainer sx={{ borderRadius: "10px", mt: "2rem" }}>
        <Table
          sx={{
            minWidth: 750,
          }}
          aria-labelledby="tableTitle"
        >
          <TableHead sx={{ backgroundColor: "#F87B03" }}>
            <TableRow
              sx={{
                "& th": {
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "#fff",
                  padding: ".5rem",
                },
              }}
            >
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortdirection={orderBy === headCell.id ? order : "asc"}
                  align={headCell.align}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleSortRequest(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {bookings.length > 0 ? (
              stableSort(bookings, getComparator(order, orderBy)).map(
                (booking, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell
                        className="ml-2"
                        style={{ background: "#f1f1f1" }}
                      >
                        {booking.memberID}
                      </TableCell>
                      <TableCell align="left" style={{ background: "#f1f1f1" }}>
                        {booking.memberCardNumber}
                      </TableCell>
                      <TableCell align="left" style={{ background: "#f1f1f1" }}>
                        {booking.venueKnownAs}
                      </TableCell>
                      <TableCell align="left" style={{ background: "#f1f1f1" }}>
                        {booking.eventTypeName}
                      </TableCell>
                      <TableCell align="left" style={{ background: "#f1f1f1" }}>
                        {formattedDate(booking.eventStart)}
                      </TableCell>
                      <TableCell align="left" style={{ background: "#f1f1f1" }}>
                        {booking.entryTypeName}
                      </TableCell>
                      <TableCell align="left" style={{ background: "#f1f1f1" }}>
                        {booking.isAttended ? "Attended" : "Missed"}
                      </TableCell>
                    </TableRow>
                  );
                }
              )
            ) : (
              <TableRow>No Records Found</TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="mt-5">
        <h4>{t("MEMBERSHIP CARDS")}</h4>
        <table className="table rounded-4 overflow-hidden bg-light mt-3 border-white">
          <thead align="center">
            <tr className="text-white background-orange">
              <th
                style={{
                  padding: "1rem 7rem 1rem 3rem",
                  background: "#F87B03",
                  color: "white",
                }}
              >
                Membership Card
              </th>
              <th
                style={{
                  textAlign: "left",
                  background: "#F87B03",
                  color: "white",
                }}
              >
                Type
              </th>
              <th style={{ background: "#F87B03", color: "white" }}>
                Issued Date
              </th>
              <th style={{ background: "#F87B03", color: "white" }}>
                Total Used
              </th>
              <th
                style={{
                  textAlign: "left",
                  background: "#F87B03",
                  color: "white",
                }}
              >
                Last Used
              </th>
            </tr>
          </thead>
          <tbody>
            {memberCards.map((memberCard, index) => (
              <tr key={index}>
                <td
                  align="center"
                  value={index}
                  style={{ background: "#f1f1f1" }}
                >
                  {memberCard.memberCardNumber}
                </td>
                <td value={index} style={{ background: "#f1f1f1" }}>
                  {memberCard.cardType}
                </td>
                <td
                  align="center"
                  style={{ background: "#f1f1f1" }}
                  value={index}
                >
                  {formattedDate(memberCard.usedFirst)}
                </td>
                <td
                  align="center"
                  style={{ background: "#f1f1f1" }}
                  value={index}
                >
                  {memberCard.totalUsed}
                </td>
                <td
                  align="left"
                  style={{ background: "#f1f1f1" }}
                  value={index}
                >
                  {memberCard.usedLast === null
                    ? "NA"
                    : formattedDate(memberCard.usedLast)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={editModel} onHide={handleModal}>
        <Modal.Body>
          <div className="member-form d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 col-xl-11 mx-auto">
                  <form onSubmit={handleSubmit}>
                    <h4 className="mb-3">
                      <BsArrowLeft
                        onClick={handleModal}
                        style={{ marginRight: "10px" }}
                      />
                      {t("Update Member")}
                    </h4>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="border form-control shadow-none bg-light"
                        value={firstName}
                        readOnly
                        placeholder="First Name"
                      />
                      <label htmlFor="floatingInput">{t("First Name")}</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="border form-control shadow-none bg-light"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                        readOnly
                        placeholder="First Name"
                      />
                      <label htmlFor="floatingInput">{t("Last Name")}</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="email"
                        className="form-control border shadow-none bg-light"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        readOnly
                        placeholder="Email"
                      />
                      <label htmlFor="floatingInput">{t("Email")}</label>
                      {emailError && (
                        <div className="mb-2">
                          <span className="text-danger mb-2">{emailError}</span>
                        </div>
                      )}
                    </div>
                    <div className="d-flex">
                      <div className="form-floating col-md-4 mb-2 pr-1">
                        <PhoneInput
                          style={{ width: "2rem" }}
                          country={"in"}
                          className="mt-2"
                          specialLabel={t("Country Code")}
                          value={mobileCountryCode}
                          onChange={(abc) => setMobileCountryCode(abc)}
                        />
                      </div>
                      <div className="form-floating col-md-8 mb-2">
                        <input
                          type="text"
                          onChange={(e) =>
                            setMobileNumber(
                              e.target.value.replace(
                                new RegExp(/[^\d]/, "ig"),
                                ""
                              )
                            )
                          }
                          value={mobileNumber}
                          maxLength={10}
                          placeholder="Mobile Number"
                          className="form-control mb-2 mt-2"
                        />
                        <label htmlFor="floatingInput">
                          {t("Mobile Number")}
                        </label>
                        {mobileNumberError && (
                          <div className="mb-2">
                            <span className="text-danger mb-2">
                              {mobileNumberError}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-floating mb-2">
                      <TextField
                        sx={{ width: "100%" }}
                        size="small"
                        type="date"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        onClick={(e) => e.target.showPicker()}
                        label={t("Date of Birth")}
                        InputLabelProps={{ shrink: true }}
                      />
                      {dobError && (
                        <div className="mb-2">
                          <span className="text-danger">{dobError}</span>
                        </div>
                      )}
                    </div>

                    <div className="form-floating mb-2">
                      <select
                        className="form-select mt-2"
                        aria-label="Floating label select example"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="0">{t("Select Gender")}</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </select>
                      <label htmlFor="floatingSelect">{t("Gender")}</label>
                    </div>

                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                        placeholder="Address"
                      />
                      <label htmlFor="floatingInput">{t("Address")}</label>
                      {addressError && (
                        <div className="mb-2">
                          <span className="text-danger">{addressError}</span>
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setPostCode(e.target.value)}
                        value={postCode}
                        placeholder="Postcode"
                      />
                      <label htmlFor="floatingInput">{t("Postcode")}</label>
                      {addressError && (
                        <div className="mb-2">
                          <span className="text-danger">{addressError}</span>
                        </div>
                      )}
                    </div>
                    <button
                      style={{
                        position: "sticky",
                        right: 0,
                        bottom: "0.5rem",
                      }}
                      type="submit"
                      className="btn background-orange form-control mt-3 text-white"
                    >
                      {t("Submit")}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MembershipDetails;

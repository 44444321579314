import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Tooltip } from "@mui/material";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import RecordAttendance from "../Event/RecordAttendance";
import StoringDataInDb from "../Event/StoringDataInDb";
import RecordAttendanceOffline from "../Event/RecordAttendanceOffline";
import configData from "../../config.js";
import { useLoader } from "../../components/SpinnerContext";
import {
  openDatabase,
  doesDatabaseExist,
  fetchHomeEvents,
  fetchEntryType,
  deleteDatabase,
} from "../db.js";

function Home(props) {
  const [events, setEvents] = useState([]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const search = props.search;
  const territoryNumber = localStorage.getItem("territoryNumber");
  const [recordAttendence, setRecordAttendence] = useState(false);
  const [currentEventId] = useState();
  const [currentEventType] = useState();
  const [downloadDataInIndexedDB, setDownloadDataInIndexedDB] = useState(false);
  const [, setDataSynced] = useState(false);
  const [offlineMessage, setOfflineMessage] = useState("");
  const [attendancedRecorded, setAttendancedRecorded] = useState(false);
  const [, setEntryType] = useState([]);
  const [offlineData, setOfflineData] = useState(false);
  const [offlineDbExists, setOfflineDbExists] = useState(false);

  const { setIsLoading } = useLoader();
  const [deleteIndexedDBMessage, setDeleteIndexedDBMessage] = useState("");
  const [isOpen, setIsOpen] = useState("no");

  const checkEventIsActive = (time) => {
    let today = new Date();
    let currentTime = today.getHours() + 2 + ":" + today.getMinutes();
    let eventTime = formattedTime(time);
    if (
      formattedDate(time) === formattedDate(today) &&
      currentTime >= eventTime
    ) {
      return true;
    }
  };
  const mode = props.mode;

  const filteredEvents = () => {
    const filterData = events.filter((event) => {
      return (
        event.eventName.toLowerCase().includes(search.toLowerCase()) ||
        event.eventTypeName.toLowerCase().includes(search.toLowerCase()) ||
        event.venueKnownAs.toLowerCase().includes(search.toLowerCase())
      );
    });
    setEvents(filterData);
  };

  useEffect(() => {
    doesDatabaseExist().then((exists) => {
      setOfflineDbExists(exists);
    });

    const fetchEventsData = async () => {
      if (window.navigator.onLine) {
        setIsLoading(true);
        try {
          const res = await fetch(
            `${configData.VENUE_URL}Events/todayevents/${territoryNumber}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await res.json();
          setIsLoading(false);
          return data;
        } catch (error) {
          setIsLoading(false);
          console.log(error);
        }
        setIsLoading(false);
      }
    };

    if (!search) {
      if (window.navigator.onLine) {
        fetchEventsData().then((data) => {
          if (data.isError) {
            navigate("/");
          }

          if (data.statusCode === 200) {
            const eventData = data.payload;
            if (eventData.length > 0) {
              setEvents(eventData);
            }
          } else {
            localStorage.setItem("offlineDataStoreWarning", "no");
            setIsOpen("no");
          }
        });
      }
    } else {
      const delay = setTimeout(() => {
        if (search) {
          filteredEvents();
        }
      }, 2000);

      return () => clearTimeout(delay);
    }
  }, [search, attendancedRecorded]);

  const formattedDate = (date) => {
    let newDate = new Date(date);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    let mnth = newDate.getMonth();
    let day = ("0" + newDate.getDate()).slice(-2);
    return [day, monthNames[mnth], newDate.getFullYear()].join(" ");
  };

  const formattedTime = (date) => {
    let newDate = new Date(date);
    return [
      ("0" + newDate.getHours()).slice(-2),
      ("0" + newDate.getMinutes()).slice(-2),
    ].join(":");
  };

  useEffect(() => {
    if (!window.navigator.onLine) {
      openDatabase().then((db) => {
        fetchHomeEvents(db).then((data) => {
          setEvents(data);
        });
      });
    }
  }, []);

  useEffect(() => {
    if (offlineMessage) {
      setTimeout(() => {
        setOfflineMessage("");
      }, 3000);
    }
  }, [offlineMessage]);

  useEffect(() => {
    if (mode === "offline") {
      // For Entry Type
      openDatabase().then((db) => {
        fetchEntryType(db, currentEventId)
          .then((data) => {
            let entryTypes = data[0];
            setEntryType(entryTypes);
          })
          .catch((error) => {});
      });
    }
  }, [currentEventId]);

  function callbackFunction(value) {
    setAttendancedRecorded(value);
  }

  function offlineDataCallback(value) {
    if (value) {
      setOfflineData(value);
    }
  }

  const handleDownloadDataInIndexedDB = (value) => {
    if (value) {
      setDownloadDataInIndexedDB(value);
      setOfflineDbExists(true);
      handleDownloadOfflineDataModal();
    }
  };

  const deleteDatabaseInIndexedDB = () => {
    // disable whole screen
    let div = document.createElement("div");
    div.className += "overlay";
    document.body.appendChild(div);
    openDatabase().then((db) => {
      deleteDatabase();
      setDeleteIndexedDBMessage("Offline Data Deleted!");
      setOfflineDbExists(false);

      setTimeout(() => {
        setDeleteIndexedDBMessage("");
        window.location.reload("/home");
      }, 2000);
    });
  };

  const handleDownloadOfflineDataModal = () => {
    let showModalValue = localStorage.getItem("offlineDataStoreWarning");
    setIsOpen(showModalValue);
    if (showModalValue === "yes") {
      let element = document.querySelector(".modalOffline");
      if (element) {
        element.style.removeProperty("display");
      }
      localStorage.setItem("offlineDataStoreWarning", "no");
    }
  };

  useEffect(() => {
    let showModalValue = localStorage.getItem("offlineDataStoreWarning");
    setIsOpen(showModalValue);
  }, [isOpen]);

  return (
    <div className="container">
      {mode === "online" && events.length > 0 ? (
        <>
          <h5 className="text-success mt-2">
            {offlineMessage ? offlineMessage : ""}
          </h5>
          {deleteIndexedDBMessage ? (
            <h5 className="text-success mt-2">{deleteIndexedDBMessage}</h5>
          ) : (
            ""
          )}
          <Button
            sx={{ float: "right", m: "0.1rem" }}
            // disabled={downloadDataInIndexedDB}
            onClick={() => handleDownloadDataInIndexedDB(true)}
          >
            <Tooltip title="Download">
              <DownloadForOfflineOutlinedIcon style={{ color: "green" }} />
            </Tooltip>
          </Button>
        </>
      ) : null}
      {mode === "online" && events.length > 0 && offlineDbExists ? (
        <>
          <Button
            sx={{ float: "right", m: "0.1rem" }}
            onClick={() => deleteDatabaseInIndexedDB()}
          >
            <Tooltip title="Delete Offline Database">
              <DeleteIcon style={{ color: "red" }} />
            </Tooltip>
          </Button>
        </>
      ) : null}
      <h4 className="m-2 mt-4">{t("TODAY'S ACTIVITY")}</h4>
      {recordAttendence ? (
        mode === "online" ? (
          <RecordAttendance
            recordAttendence={recordAttendence}
            setRecordAttendence={setRecordAttendence}
            eventID={currentEventId}
            handleModal={props.handleModal}
            eventTypeName={currentEventType}
            mode={mode}
            callbackFunction={callbackFunction}
          />
        ) : (
          <RecordAttendanceOffline
            recordAttendence={recordAttendence}
            setRecordAttendence={setRecordAttendence}
            eventID={currentEventId}
            t={t}
            mode={mode}
            offlineDataCallback={offlineDataCallback}
          />
        )
      ) : null}

      {downloadDataInIndexedDB ? (
        <StoringDataInDb
          events={events}
          setDownloadDataInIndexedDB={setDownloadDataInIndexedDB}
          setDataSynced={setDataSynced}
          setOfflineMessage={setOfflineMessage}
        />
      ) : null}
      <div className="mt-3">
        <table className="table table-striped bg-light mt-3 border-white rounded rounded-4 overflow-hidden">
          <thead>
            <tr className="text-white background-orange text-truncate">
              <th scope="col">{t("Event Name")}</th>
              <th scope="col">{t("Date")}</th>
              <th scope="col">{t("Time")}</th>
              <th scope="col">{t("Venue")}</th>
              <th scope="col">{t("Event Type")}</th>
              <th scope="col">{t("Pre-Books")}</th>
              <th scope="col">{t("Attendances")}</th>
              <th scope="col">{t("Record Attendance")}</th>
              <th scope="col">{t("Status")}</th>
            </tr>
          </thead>
          <tbody>
            {events.length > 0 ? (
              events.map((event, index) => (
                <tr key={index}>
                  <td>
                    <Link
                      to={"/eventDetails"}
                      state={{
                        eventId: event.eventID,
                        eventTypeName: event.eventTypeName,
                        offlineData: offlineData,
                      }}
                    >
                      {event.eventName ? event.eventName : ""}
                    </Link>
                  </td>
                  <td>
                    {event.eventStart ? formattedDate(event.eventStart) : ""}
                  </td>
                  <td>
                    {event.eventStart ? formattedTime(event.eventStart) : ""}
                  </td>
                  <td>{event.venueKnownAs ? event.venueKnownAs : ""}</td>
                  <td>{event.eventTypeName ? event.eventTypeName : ""}</td>
                  <td align="center">
                    {event.totalBookings ? event.totalBookings : 0}
                  </td>
                  <td align="center">
                    {event.totalAttendence ? event.totalAttendence : 0}
                  </td>
                  <td>
                    {
                      event.recordAction === "Record" &&
                      checkEventIsActive(event.eventStart) ? (
                        <span
                          style={{
                            color: "royalblue",
                            cursor: "pointer",
                            textDecoration: "underLine",
                          }}
                          onClick={() =>
                            navigate("/searchMember", {
                              state: { eventID: event.eventID },
                            })
                          }
                          disabled={true}
                        >
                          {event.recordAction}
                        </span>
                      ) : (
                        event.recordAction
                      )
                      //  : event.recordAction
                    }
                  </td>
                  <td>
                    <span
                      className={
                        event.eventStatusName === "Ended"
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      {event.eventStatusName}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center">
                  Event not found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Home;

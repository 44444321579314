import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import "react-phone-input-2/lib/material.css";
import { useTranslation } from "react-i18next";
import configData from "../../config.js";
import { phoneNoMapping, maskEmail } from "../CommonFunctions.js";
import MemberDetailModal from "../modal/MemberDetailModal";

function MemberDetails() {
  const { t } = useTranslation();
  const location = useLocation();
  const findMemberID = location.state ? location.state.memberID : "";
  const [editModel, setEditModel] = useState(false);
  const [email, setEmail] = useState("");
  const [, setFirstName] = useState("");
  const [, setLastName] = useState("");
  const [name, setName] = useState("");
  const [, setPostCode] = useState("");
  const [, setMobileNumber] = useState("");
  const [, setMobileCountryCode] = useState("");
  const [, setDob] = useState(new Date());
  const [, setGender] = useState("");
  const [, setAddress] = useState("");
  const [bookings, setBookings] = useState([]);
  const [bookingCount, setBookingCount] = useState([]);
  const [member, setMember] = useState([]);
  const [memberCards, setMemberCards] = useState([]);
  const [, setMemberId] = useState("");
  const [, setMobileNumberError] = useState("");
  const [, setAddressError] = useState("");
  const [, setTownError] = useState("");
  const [, setTown] = useState("");
  const navigate = useNavigate();
  const defaultProfilePic = "./Images/defaultpp.png";
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const token = localStorage.getItem("token");
  const [memberUpdated, setMemberUpdated] = useState(false);
  const [newNumberAdd, setNewNumberAdd] = useState(false);
  const [newCardNumber, setNewCardNumber] = useState();

  const headCells = [
    {
      id: "memberCardNumber",
      label: `${t("Membership Card")}`,
      align: "center",
    },
    {
      id: "venueKnownAs",
      label: `${t("Venue")}`,
      align: "left",
    },
    {
      id: "eventTypeName",
      label: `${t("Event Type")}`,
      align: "left",
    },
    {
      id: "eventStart",
      label: `${t("Entry Date")}`,
      align: "center",
    },
    {
      id: "entryTypeName",
      label: `${t("Entry Type")}`,
      align: "left",
    },
    {
      id: "isAttended",
      label: `${t("Attendance Check In")}`,
      align: "left",
    },
  ];

  const handleModal = (data) => {
    if (!editModel) {
      let countryCode = data.mobileCountryCode;
      let mobileNumber = data.mobileNumber;
      if (data.mobileNumber.length > 10) {
        let num = data.mobileNumber.toString();
        countryCode = num.substring(0, 2);
        mobileNumber = num.substring(2);
      }
      let date = formattedDate(data.dateOfBirth);
      setMemberId(data.memberID);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setEmail(data.email);
      setMobileNumber(mobileNumber);
      setMobileCountryCode(countryCode);
      setDob(date);
      setGender(data.gender);
      setAddress(data.addr1);
      setPostCode(data.addrPostcode);
      setTown(data.addrTown);
      setEditModel(true);
      setMobileNumberError("");
      setAddressError("");
      setTownError("");
    } else {
      setEditModel(false);
    }
  };

  const fetchMemberData = async (findMemberID) => {
    const res = await fetch(
      `${configData.VENUE_URL}Member/${findMemberID}/getmember`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  useEffect(() => {
    if (memberUpdated) {
      fetchMemberData(findMemberID);
      setMemberUpdated(false);
    }
  }, [memberUpdated]);

  const fetchBookingsData = async () => {
    const res = await fetch(
      `${configData.VENUE_URL}Member/${findMemberID}/getbookings`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const fetchMemberCardsData = async () => {
    const res = await fetch(
      `${configData.VENUE_URL}Member/${findMemberID}/getmembercards`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  useEffect(() => {
    if (findMemberID) {
      fetchMemberData(findMemberID).then((data) => {
        if (data.isError) {
          navigate("/");
        }

        if (data.statusCode === 200) {
          const memberData = data.payload;
          setMember(memberData);
          let name = `${memberData.firstName} ${memberData.lastName}`;
          setName(name);
        }
      });
    }
  }, [
    findMemberID,
    member.gender,
    member.mobileNumber,
    member.addr1,
    member.addrPostcode,
    member.addrTown,
  ]);

  useEffect(() => {
    if (findMemberID && memberUpdated) {
      fetchMemberData(findMemberID).then((data) => {
        if (data.isError) {
          navigate("/");
        }

        if (data.statusCode === 200) {
          const memberData = data.payload;
          setMember(memberData);
          let name = `${memberData.firstName} ${memberData.lastName}`;
          setName(name);
        }
      });
      fetchBookingsData().then((data) => {
        if (data.isError) {
          navigate("/");
        }

        if (data.statusCode === 200) {
          const bookingData = data.payload.bookingHistories;
          const countDetails = data.payload.bookingHistoryCount;

          setBookings(bookingData);
          setBookingCount(countDetails);
        }
      });

      fetchMemberCardsData().then((data) => {
        if (data.isError) {
          navigate("/");
        }

        if (data.statusCode === 200) {
          const memberCardsData = data.payload;
          setMemberCards(memberCardsData);
        }
      });
    }
  }, [memberUpdated]);

  useEffect(() => {
    if (findMemberID) {
      fetchBookingsData().then((data) => {
        if (data.isError) {
          navigate("/");
        }

        if (data.statusCode === 200) {
          const bookingData = data.payload.bookingHistories;
          const countDetails = data.payload.bookingHistoryCount;

          setBookings(bookingData);
          setBookingCount(countDetails);
        }
      });

      fetchMemberCardsData().then((data) => {
        if (data.isError) {
          navigate("/");
        }

        if (data.statusCode === 200) {
          const memberCardsData = data.payload;
          setMemberCards(memberCardsData);
        }
      });
    }
  }, [editModel, findMemberID]);

  const handleAddCard = async () => {
    if (newCardNumber.length > 3) {
      const res = await fetch(
        `${configData.VENUE_URL}Member/AddMemberCardNumber/${findMemberID}/${newCardNumber}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (
        data.statusCode === 200 ||
        data.statusCode === 201 ||
        data.statusCode === 202
      ) {
        setNewNumberAdd(false);
        setMemberUpdated(true);
        setNewCardNumber();
      }
    }
  };

  const renderNewNumberModal = () => {
    if (newNumberAdd) {
      return (
        <div className="modal modalOffline show md-main-div">
          <Modal.Dialog
            className="offlineData"
            sx={{ margin: "9rem 0 0 0rem" }}
          >
            <Modal.Header>
              <Modal.Title>Add New Member Card Number</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div>
                <label className="ml-1 mb-2">{"Enter New Card Number :"}</label>
                <div className="form-floating mb-2">
                  <input
                    type="text"
                    id="memberCardNumber"
                    className="border form-control shadow-none bg-light"
                    value={newCardNumber}
                    onChange={(e) => setNewCardNumber(e.target.value)}
                    placeholder="Member Card Number"
                    data-1p-ignore="true"
                  />
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setNewNumberAdd(false)}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={() => handleAddCard()}>
                Save
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      );
    }
  };

  const formattedDate = (date) => {
    let newDate = new Date(date);
    if (newDate.getFullYear() === 1) return;
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    let mnth = newDate.getMonth();
    let day = ("0" + newDate.getDate()).slice(-2);
    return [day, monthNames[mnth], newDate.getFullYear()].join(" ");
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleNewNumberAdd = (val) => {
    setNewNumberAdd(val);
  };

  return (
    <div className="container">
      <h4 className="m-2 mt-4">{t("MEMBER DETAILS")}</h4>
      <table className="table table-responsive rounded-4 bg-light mt-3 border-white">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-3">
              <img
                width="130rem"
                height="130rem"
                alt="member"
                className="rounded-circle"
                src={
                  member.memberAvatarURL
                    ? member.memberAvatarURL
                    : defaultProfilePic
                }
              />
              <h5 className="pt-3 font-600">{name}</h5>
            </td>
            <td className="pt-4" style={{ minWidth: "15rem" }}>
              <div className="bg-white border rounded p-2 mb-2 mh-18">
                <span className="opacity-50">
                  {t("Membership Card Number")}
                </span>
                <br />
                <span className="font-600">{member.memberCardNumber}</span>
              </div>
              <div className="bg-white border rounded p-2 mh-18">
                <span className="opacity-50">{t("Postal Code")}</span>
                <br />
                <span className="font-600">{member.addrPostcode}</span>
              </div>
            </td>
            <td className="pt-4" style={{ minWidth: "15rem" }}>
              <div className="bg-white border rounded p-2 mb-2 mh-18">
                <span className="opacity-50">{t("Email")}</span>
                <br />
                <span className="font-600">
                  {email ? maskEmail(email) : maskEmail(member.email)}
                </span>
              </div>
              <div className="bg-white border rounded p-2 mb-2 mh-18">
                <span className="opacity-50">{t("Town")}</span>
                <br />
                <span className="font-600">{member.addrTown}</span>
              </div>
            </td>
            <td className="pt-4" style={{ minWidth: "10rem" }}>
              <div className="bg-white border rounded p-2 mb-2 mh-18">
                <span className="opacity-50">{t("Contact")}</span>
                <br />
                <span className="font-600">
                  {member.mobileCountryCode && member.mobileNumber
                    ? `+${member.mobileCountryCode}${phoneNoMapping(
                        member.mobileNumber
                      )}`
                    : member.mobileCountryCode === null && member.mobileNumber
                    ? `+${phoneNoMapping(member.mobileNumber)}`
                    : null}
                </span>
              </div>
            </td>
            <td className="pt-4">
              <div className="bg-white border rounded p-2 mb-2 mh-18">
                <span className="opacity-50">{t("Gender")}</span>
                <br />
                <span className="font-600">
                  {member.gender === 1
                    ? "Male"
                    : member.gender === 2
                    ? "Female"
                    : ""}
                </span>
              </div>
            </td>
            <td style={{ minWidth: "6rem" }}>
              <button
                className="btn btn-info rounded-pill text-white"
                onClick={(e) => handleModal(member, e)}
              >
                <FaRegEdit className="mb-1" /> {t("Update")}
              </button>
              <br />
              <button
                className="btn btn-info rounded-pill text-white mt-3"
                onClick={(e) => handleNewNumberAdd(true, member, e)}
              >
                {t("Add New Card")}
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="mt-5">
        <h4>{t("BOOKINGS")}</h4>
        <table className="table rounded-4 overflow-hidden bg-light mt-3 border-white">
          <thead>
            <tr className="text-white background-orange text-center">
              <th scope="col">{t("Total Bookings")}</th>
              <th scope="col">{t("Event Attended")}</th>
              <th scope="col">{t("Event Missed")}</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td>{bookingCount.totalBookings}</td>
              <td>{bookingCount.eventsAttended}</td>
              <td>{bookingCount.eventsNotAttended}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <TableContainer sx={{ borderRadius: "10px", mt: "2rem" }}>
        <Table
          sx={{
            minWidth: 750,
          }}
          aria-labelledby="tableTitle"
        >
          <TableHead sx={{ backgroundColor: "#F87B03" }}>
            <TableRow
              sx={{
                "& th": {
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "#fff",
                  padding: ".5rem",
                },
              }}
            >
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortdirection={orderBy === headCell.id ? order : "asc"}
                  align={headCell.align}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleSortRequest(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(bookings, getComparator(order, orderBy)).map(
              (booking, index) => {
                return (
                  <TableRow hover key={index}>
                    <TableCell className="ml-2">
                      {booking.memberCardNumber}
                    </TableCell>
                    <TableCell align="left">{booking.venueKnownAs}</TableCell>
                    <TableCell align="left">{booking.eventTypeName}</TableCell>
                    <TableCell align="left">
                      {formattedDate(booking.eventStart)}
                    </TableCell>
                    <TableCell align="left">{booking.entryTypeName}</TableCell>
                    <TableCell align="left">
                      {booking.isAttended ? "Attended" : "Missed"}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="mt-5">
        <h4>{t("MEMBERSHIP CARDS")}</h4>
        <table className="table rounded-4 overflow-hidden bg-light mt-3 border-white">
          <thead align="center">
            <tr className="text-white background-orange">
              <th style={{ padding: "1rem 7rem 1rem 3rem" }}>
                Membership Card
              </th>
              <th style={{ textAlign: "left" }}>Type</th>
              <th>Issued Date</th>
              <th>Total Used</th>
              <th style={{ textAlign: "left" }}>Last Used</th>
            </tr>
          </thead>
          <tbody>
            {memberCards.map((memberCard, index) => (
              <tr key={index}>
                <td align="center" value={index}>
                  {memberCard.memberCardNumber}
                </td>
                <td value={index}>{memberCard.cardType}</td>
                <td align="center" value={index}>
                  {formattedDate(memberCard.usedFirst)}
                </td>
                <td align="center" value={index}>
                  {memberCard.totalUsed}
                </td>
                <td align="left" value={index}>
                  {memberCard.usedLast === null
                    ? "NA"
                    : formattedDate(memberCard.usedLast)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {renderNewNumberModal()}
      <MemberDetailModal
        showModel={editModel}
        setMemberUpdated={setMemberUpdated}
        isDetailPage={true}
        findMemberID={findMemberID}
        setShowModel={setEditModel}
      />
    </div>
  );
}

export default MemberDetails;

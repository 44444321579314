import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../images/ceroc-logo.png";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaBars,
  FaHome,
  FaUsers,
  FaRegCalendarAlt,
  FaFolderMinus,
} from "react-icons/fa";
import { FiSettings, FiLogOut } from "react-icons/fi";
import { CiLocationOn } from "react-icons/ci";
import MemberDetails from "./pages/MemberDetails";
import MemberLists from "./pages/MemberLists";
import AddMember from "./pages/AddMember";
import Home from "./pages/Home";
import FindMember from "./pages/FindMember";
import { InputLabel, MenuItem, Select } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import EventDetails from "./Event/EventDetails";
import RecordAttendance from "./Event/RecordAttendance";
import CreateEvent from "./Event/CreateEvent";
import configData from "../config.js";
import MembershipDetails from "./pages/MembershipDetails";
import SearchMember from "./pages/SearchMember";
import {
  openDatabase,
  fetchActiveUsers,
  fetchBookedCount,
  fetchBookingAmount,
} from "./db.js";

const languages = [
  {
    code: "fr",
    name: "French",
    country_code: "fr",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
];

function Sidebar(props) {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [subMenu, setSubMenu] = useState(false);
  const [subSetting, setSubSetting] = useState(false);
  const [model, setModel] = useState(false);
  const [findModel, setFindModel] = useState(false);
  const [eventSearch] = useState(null);
  const [eventsSearch, setEventsSearch] = useState(null);
  const [, setProfilePic] = useState("./Images/defaultpp.png");
  const memberId = localStorage.getItem("memberId");
  const token = localStorage.getItem("token");
  const headerImage = localStorage.getItem("profilepic");
  const territoryNumber = localStorage.getItem("territoryNumber");
  const territoryName = localStorage.getItem("territoryName");

  const navigate = useNavigate();
  const [mode, setMode] = useState("");
  const [addMemberOfflineData, setAddMemberOfflineData] = useState(false);
  const [eventCount, setEventCount] = useState();
  const [bookingCount, setBookingCount] = useState();
  const [eventID, setEventID] = useState("");
  const [activeUsers, setActiveUsers] = useState([]);
  const [isAttendanceUpdated, setIsAttendanceUpdated] = useState(false);
  const [entryTypeData, setEntryTypeData] = useState();
  const [attendanceData, setAttendanceData] = useState();

  const toggleSidebar = () => {
    if (!toggle) {
      setToggle(true);
      setSubSetting(false);
    } else {
      setToggle(false);
    }
  };

  useEffect(() => {
    if (!navigator.onLine) {
      setMode("offline");
    } else {
      setMode("online");
    }
    window.addEventListener("offline", () => setMode("offline"));
    window.addEventListener("online", () => setMode("online"));

    setProfilePic(headerImage);
  }, []);

  useEffect(() => {
    if (mode === "offline") {
      openDatabase().then((db) => {
        fetchActiveUsers(db)
          .then((data) => {
            const users = data[0];
            setActiveUsers(users.activeUsers);
          })
          .catch((error) => {});
      });
    }
  }, [mode]);

  const fetchCount = async () => {
    if (mode === "online" && eventID) {
      await fetch(
        `${configData.VENUE_URL}Member/GetBookedCountByEventId?eventid=${eventID}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.payload === null) {
            setEventCount();
          } else {
            setEventCount(data?.payload[0]);
            let tempData = data?.payload?.map((item) => {
              return {
                entryTypeId: item.entryTypeId,
                entryTypeName: item.entryTypeName,
                attendanceCount: item.attendanceCount,
              };
            });
            setEntryTypeData(tempData);
          }
        });

      await fetch(
        `${configData.VENUE_URL}Member/GetBookingAmountByEventId?eventid=${eventID}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.payload === null) {
            setBookingCount();
          } else {
            setBookingCount(data?.payload);
          }
        });
    } else if (mode !== "online" && eventID) {
      openDatabase().then((db) => {
        fetchBookedCount(db, eventID)
          .then((data) => {
            setEventCount(data[0]?.eventCount[0]);
            let tempData = data[0]?.eventCount?.map((item) => {
              return {
                entryTypeName: item.entryTypeName,
                attendanceCount: item.attendanceCount,
              };
            });
            setEntryTypeData(tempData);
          })
          .catch((error) => {});
        fetchBookingAmount(db, eventID)
          .then((data) => {
            setBookingCount(data[0]?.Booking);
          })
          .catch((error) => {});
      });
    }
  };

  useEffect(() => {
    if (isAttendanceUpdated) {
      fetchCount();
      setIsAttendanceUpdated(false);
    }
  }, [isAttendanceUpdated]);

  useEffect(() => {
    if (props.page === "SearchMember" || props.page === "EventDetails") {
      setToggle(true);
      fetchCount();
    }
  }, [props.page, eventID]);

  let component = "";
  let heading = localStorage.getItem("username");

  if (props.page === "find-member") {
    component = <MemberLists />;
  }
  if (props.page === "member-details") {
    component = <MemberDetails />;
  }
  if (props.page === "home") {
    component = (
      <Home search={eventSearch} handleModal={handleModal} mode={mode} />
    );
  }
  if (props.page === "EventDetails") {
    component = (
      <EventDetails
        handleModal={handleModal}
        toggle={toggle}
        entryTypeData={entryTypeData}
        setEventId={setEventID}
        mode={mode}
        attendanceData={attendanceData}
        addMemberOfflineData={addMemberOfflineData}
      />
    );
  }
  if (props.page === "EventListing") {
    component = (
      <CreateEvent
        handleModal={handleModal}
        toggle={toggle}
        mode={mode}
        search={eventsSearch}
      />
    );
  }
  if (props.page === "RecordAttendance") {
    component = <RecordAttendance />;
  }
  if (props.page === "SearchMember") {
    component = (
      <SearchMember
        toggle={toggle}
        mode={mode}
        setEventID={setEventID}
        setIsAttendanceUpdated={setIsAttendanceUpdated}
        eventID={eventID}
        setAttendanceData={setAttendanceData}
      />
    );
  }
  if (props.page === "MembershipDetails") {
    component = <MembershipDetails />;
  }

  const menuToggle = () => {
    if (!subMenu) {
      setSubMenu(true);
    } else {
      setSubMenu(false);
    }
  };

  const settingToggle = () => {
    if (!subSetting) {
      setSubSetting(true);
    } else {
      setSubSetting(false);
    }
  };

  function handleModal() {
    if (!model) {
      setModel(true);
    } else {
      setModel(false);
    }
  }

  const handleFindModal = () => {
    if (!findModel) {
      setFindModel(true);
    } else {
      setFindModel(false);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    activityLog()
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
    navigate("/");
  };

  const activityLog = async () => {
    const activityLogData = {
      memberID: memberId,
      activityTypeName: "Logout",
      activityDescription: "User Logged Out",
    };
    const res = await fetch(
      `${configData.VENUE_URL}Application/addactivitylog`,
      {
        method: "post",
        body: JSON.stringify(activityLogData),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  function offlineDataCallback(value) {
    if (value) {
      setAddMemberOfflineData(value);
      component = (
        <EventDetails
          handleModal={handleModal}
          toggle={toggle}
          mode={mode}
          addMemberOfflineData={value}
        />
      );
    }
  }

  const getEventData = (type) => {
    switch (type) {
      case "male":
        return attendanceData?.filter((item) => item?.gender === 1).length ?? 0;
      case "female":
        return attendanceData?.filter((item) => item?.gender === 2).length ?? 0;
      case "total":
        return attendanceData?.length ?? 0;
      default:
        break;
    }
  };

  const getBookingCostData = (type) => {
    switch (type) {
      case "cash":
        return (
          attendanceData
            ?.filter((event) => event?.paymentMethodName === "Cash")
            .reduce((sum, event) => sum + event?.entryFeePaid, 0) ?? 0
        );
      case "card":
        return (
          attendanceData
            ?.filter((event) => event?.paymentMethodName === "Card")
            .reduce((sum, event) => sum + event?.entryFeePaid, 0) ?? 0
        );
      case "pre-booked":
        return (
          attendanceData
            ?.filter((event) => event?.paymentMethodName === "Pre")
            .reduce((sum, event) => sum + event?.entryFeePaid, 0) ?? 0
        );
      case "online":
        return (
          attendanceData
            ?.filter((event) => event?.paymentMethodName === "Online")
            .reduce((sum, event) => sum + event?.entryFeePaid, 0) ?? 0
        );
      case "total":
        return (
          attendanceData?.reduce(
            (sum, event) => sum + event?.entryFeePaid,
            0
          ) ?? 0
        );

      default:
        break;
    }
  };

  return (
    <div>
      <div className="wrapper">
        <nav id="sidebar" className={toggle ? "p-2 collapse-side" : "p-2"}>
          <div className="sidebar-header d-flex mt-3">
            <img
              src={logo}
              alt="logo"
              width={toggle ? "40" : "50"}
              height={toggle ? "40" : "50"}
              className="logo border-rounded"
            />
            <h3 className="p-2 bold text-black">CEROC</h3>
          </div>
          <div id="nav-listing" className="d-flex m-1 mt-4">
            <FaFolderMinus id="nav-icon" className="sd-nav-tx" />
            {mode === "offline" ? (
              <div className="d-flex-none m-1">
                <div style={{ fontSize: "0.7rem" }}>{t("Offline")}</div>
                <div className="sd-nav-sub-tx">{t("Active")}</div>
              </div>
            ) : (
              <div className="d-flex-none m-1">
                <div style={{ fontSize: "0.7rem" }}>{t("Online")}</div>
                <div className="sd-nav-sub-tx">{t("Active")}</div>
              </div>
            )}
          </div>

          <ul className="list-unstyled components">
            <h5 className={toggle ? "nav-title" : ""}>{t("Menu")}</h5>
            <li
              id="nav-listing"
              className={
                props.page === "home" && !findModel && !model ? "active" : ""
              }
            >
              <Link to={"/home"} className="nav-link">
                <FaHome id="nav-icon" className="mb-1" /> {t("Home")}
              </Link>
            </li>
            {mode === "online" ? (
              <>
                <li
                  id="nav-listing"
                  className={
                    props.page === ("EventListing" || "EventDetails") &&
                    !findModel &&
                    !model
                      ? "active"
                      : ""
                  }
                >
                  <Link to={"/EventListing"} className="nav-link">
                    <FaRegCalendarAlt id="nav-icon" className="mb-1" />{" "}
                    {t("Events")}
                  </Link>
                </li>
                <li id="nav-listing" className={findModel ? "active" : ""}>
                  <Link className="nav-link" onClick={handleFindModal}>
                    <FaUsers id="nav-icon" className="mb-1" />{" "}
                    {t("Member Details")}
                  </Link>
                </li>
                <li id="nav-listing" className="nav-item has-submenu">
                  <Link
                    className="nav-link dropdown-toggle2"
                    onClick={() => {
                      if (!subSetting) {
                        setToggle(false);
                      }
                      settingToggle();
                    }}
                  >
                    <FiSettings id="nav-icon" className="mb-1" />{" "}
                    {t("Settings")}
                  </Link>
                  <ul
                    className={`"submenu collapse ${subSetting ? "show" : ""} `}
                  >
                    <InputLabel labelid="demo-select-small">
                      {t("Language")}
                    </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      label="Language"
                      value={localStorage.getItem("i18nextLng") ?? "en"}
                      onChange={(e) => i18next.changeLanguage(e.target.value)}
                    >
                      {languages.map(({ code, name }) => (
                        <MenuItem key={name} value={code}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </ul>
                </li>
                <li id="nav-listing" className="nav-item ml-2 mt-1">
                  <button
                    className="bg-white border-0 nav-link sd-nav-con"
                    onClick={handleLogout}
                  >
                    <FiLogOut id="nav-icon" className="mb-1" /> {t("Logout")}
                  </button>
                </li>
              </>
            ) : (
              <>
                <li
                  id="nav-listing"
                  className={
                    props.page === "member-details" && !findModel && !model
                      ? "active has-submenu"
                      : "has-submenu"
                  }
                >
                  <Link
                    className="nav-link dropdown-toggle"
                    onClick={menuToggle}
                  >
                    <FaUsers id="nav-icon" className="mb-1" /> {t("Members")}
                  </Link>
                  <ul
                    className={`"submenu collapse ${
                      subMenu ? (toggle ? "show sub-coll" : "show") : ""
                    } `}
                  >
                    <li
                      id="nav-listing"
                      className={
                        model ? "active list-unstyled" : "list-unstyled"
                      }
                    >
                      <Link
                        className={`nav-link list-unstyled ${
                          toggle ? "sub-coll" : ""
                        }`}
                        onClick={handleModal}
                      >
                        {toggle ? "Add" : t("Add Member")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </ul>
        </nav>

        <div id="content">
          <nav className="navbar navbar-expand navbar-light bg-light">
            <div className="container-fluid">
              <button
                type="button"
                onClick={toggleSidebar}
                className="btn btn-warning"
              >
                <FaBars />
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <h3 className="m-2 text-capitalize">{`hello ${heading}`}</h3>
                {props.page === "SearchMember" && (
                  <div
                    className="mt-1"
                    style={{ marginLeft: "10px", display: "flex" }}
                  >
                    <table className="table mb-0 bg-light border-white rounded rounded-4 overflow-hidden">
                      <thead style={{ height: "1px", lineHeight: "1px" }}>
                        <tr
                          className="background-white text-truncate text-center"
                          style={{ color: "black !important" }}
                        >
                          <th>{t("MALE")}</th>
                          <th>{t("FEMALE")}</th>
                          <th>{t("TOTAL")}</th>
                        </tr>
                      </thead>
                      <tbody style={{ height: "1px", lineHeight: "1px" }}>
                        {mode === "online" ? (
                          <tr>
                            <td className="count-table" align="center">
                              {eventCount?.maleCount}
                            </td>
                            <td className="count-table" align="center">
                              {eventCount?.femaleCount}
                            </td>
                            <td className="count-table" align="center">
                              {eventCount?.totalCount}
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td className="count-table" align="center">
                              {getEventData("male")}
                            </td>
                            <td className="count-table" align="center">
                              {getEventData("female")}
                            </td>
                            <td className="count-table" align="center">
                              {getEventData("total")}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <table
                      className="table mb-0 bg-light border-white rounded rounded-4 overflow-hidden"
                      style={{ marginLeft: "10px" }}
                    >
                      <thead style={{ height: "1px", lineHeight: "1px" }}>
                        <tr
                          className="background-white text-truncate text-center"
                          style={{ color: "black !important" }}
                        >
                          <th>{t("Total Cash")}</th>
                          <th>{t("Total Card")}</th>
                          <th>{t("Total Pre-Booked")}</th>
                          <th>{t("Total Online")}</th>
                          <th>{t("TOTAL")}</th>
                        </tr>
                      </thead>
                      <tbody style={{ height: "1px", lineHeight: "1px" }}>
                        {mode === "online" ? (
                          <tr>
                            <td className="count-table" align="center">
                              {bookingCount?.totalCash}.00
                            </td>
                            <td className="count-table" align="center">
                              {bookingCount?.totalCardPayment}.00
                            </td>
                            <td className="count-table" align="center">
                              {bookingCount?.totalPreBookedAmount}.00
                            </td>
                            <td className="count-table" align="center">
                              {bookingCount?.totalOnline}.00
                            </td>
                            <td className="count-table" align="center">
                              {bookingCount?.grandTotal}.00
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td className="count-table" align="center">
                              {getBookingCostData("cash")}.00
                            </td>
                            <td className="count-table" align="center">
                              {getBookingCostData("card")}.00
                            </td>
                            <td className="count-table" align="center">
                              {getBookingCostData("pre-booked")}.00
                            </td>
                            <td className="count-table" align="center">
                              {getBookingCostData("online")}.00
                            </td>
                            <td className="count-table" align="center">
                              {getBookingCostData("total")}.00
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <button
                      style={{
                        marginTop: "2px",
                        width: "100%",
                        padding: "10px",
                        border: "none",
                        borderRadius: "5px",
                        alignSelf: "center",
                        marginLeft: "10px",
                        backgroundColor: "#00B4FF",
                        fontWeight: 700,
                        color: "#fff",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#00B4FF",
                        },
                      }}
                      onClick={() => {
                        navigate("/eventDetails", {
                          state: {
                            eventId: eventID,
                            attendanceData: attendanceData,
                          },
                        });
                      }}
                    >
                      {t("Go to event details")}
                    </button>
                  </div>
                )}
                <ul className="nav navbar-nav m-auto">
                  <li className="nav-item active"></li>
                </ul>
                <Select
                  sx={{
                    marginRight: "1rem",
                    height: "2.6rem",
                    fontWeight: "700",
                  }}
                  labelId="demo-select-small"
                  value={territoryName}
                  disabled={true}
                  className="rounded-5"
                >
                  <MenuItem key={territoryNumber} value={territoryName}>
                    <CiLocationOn
                      style={{ color: "black", marginRight: "0.5rem" }}
                    />
                    {territoryName}
                  </MenuItem>
                </Select>
                {props.page === "EventListing" ? (
                  <div className="">
                    <form className="nosubmit form-control">
                      <input
                        onChange={(e) => setEventsSearch(e.target.value)}
                        className="nosubmit"
                        type="search"
                        value={eventsSearch}
                        placeholder={t("Search Events")}
                      />
                    </form>
                  </div>
                ) : null}
                <ul
                  className="header-nav nav navbar-nav ms-3"
                  role="navigation"
                ></ul>
              </div>
            </div>
          </nav>

          {component}
        </div>
        <AddMember
          activeUsers={activeUsers}
          mode={mode}
          model={model}
          parentCallback={handleModal}
          addMemberOfflineDataCallback={offlineDataCallback}
        />
        <FindMember model={findModel} parentCallback={handleFindModal} t={t} />
      </div>
    </div>
  );
}

export default Sidebar;

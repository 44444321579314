import axios from "axios";
import React from "react";
import { useEffect } from "react";
import configData from "./../../config.js";
import {
  openDatabase,
  clearRecordAttendance,
  clearUpdateAttendance,
  fetchAddMember,
  clearCreateAttendance,
  clearAddExpenditure,
  clearDeleteAttendance,
  clearDeleteExpenditure,
  clearUpdateMember,
} from "../db.js";

const SyncRecordAttendanceData = ({
  eventID,
  handleCallback,
  createAttendanceCallback,
  updateAttendanceCallback,
  deleteAttendanceData,
  offlineDataCallback,
  addMemberSyncDataCallback,
  offlineCreateAttendanceData,
  offlineUpdateMemberData,
  offlineRecordAttendanceData,
  offlineUpdateAttendanceData,
  offlineUpdateAddExpenditureData,
  offlineDeleteExpenseData,
  setSync,
  // addMemberOfflineData
}) => {
  const token = localStorage.getItem("token");
  let singleCallFLag = 0;

  // Syncing Record Attendance Data
  const syncOfflineMemberRecordAttendance = async (memberList) => {
    let newMemberList = [];
    memberList.filter((memberAttendance) => {
      if (typeof memberAttendance.id === "number") {
        newMemberList.push(memberAttendance);
      }
    });

    if (newMemberList?.length > 0) {
      axios
        .put(
          `${configData.VENUE_URL}Attendance/recordattendance`,
          JSON.stringify(memberList),
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          createAttendanceCallback(res.data);
          openDatabase().then((db) => {
            clearRecordAttendance(db, eventID);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Syncing Update Attendance Data
  const syncOfflineMemberUpdateAttendance = async (memberList) => {
    let newMemberList = [];
    memberList.filter((memberAttendance) => {
      if (typeof memberAttendance.id === "number") {
        newMemberList.push(memberAttendance);
      }
    });
    if (newMemberList?.length > 0) {
      await axios
        .put(
          `${configData.VENUE_URL}Attendance/updateattendance`,
          JSON.stringify(memberList),
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          updateAttendanceCallback(res.data);
          openDatabase().then((db) => {
            clearUpdateAttendance(db, eventID);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //Syncing Create Attendance Data
  const syncOfflineMemberCreateAttendance = async (memberList) => {
    let newMemberList = [];
    memberList.filter((memberAttendance) => {
      if (typeof memberAttendance.id === "number") {
        newMemberList.push(memberAttendance);
      }
    });

    openDatabase().then((db) => {
      fetchAddMember(db).then((data) => {
        if (data) {
          if (newMemberList?.length > 0) {
            axios
              .post(
                `${configData.VENUE_URL}Attendance/createattendance`,
                JSON.stringify(memberList),
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                createAttendanceCallback(res.data);
                openDatabase().then((db) => {
                  clearCreateAttendance(db, eventID).then(() => {
                    if (offlineUpdateMemberData.length > 0) {
                      syncOfflineUpdateMemberData(offlineUpdateMemberData);
                    }
                  });
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      });
    });
  };

  //Syncing Add expense Data
  const syncOfflineAddExpense = async (memberList) => {
    if (memberList?.length > 0) {
      memberList.map(async (e, i) => {
        await axios
          .post(`${configData.VENUE_URL}Events/addexpense`, JSON.stringify(e), {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            handleCallback(res.data);
            if (res.data.statusCode === 200 && i === memberList.length - 1) {
              openDatabase().then((db) => {
                clearAddExpenditure(db, eventID);
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  //Syncing delete attendance
  const syncOfflineDeleteAttendance = async (memberList) => {
    if (memberList?.length > 0) {
      memberList.map(async (e, i) => {
        axios
          .delete(
            `${configData.BOOKING_URL}Members/DeleteAttendences/${e.attendanceID}`,
            {
              method: "DELETE",
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            handleCallback(res.data);
            if (res.data.result && i === memberList.length - 1) {
              openDatabase().then((db) => {
                clearDeleteAttendance(db, eventID);
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  //Syncing delete expense Data
  const syncOfflineDeleteExpense = async (memberList) => {
    if (memberList?.length > 0) {
      memberList.map(async (e, i) => {
        axios
          .delete(
            `${configData.VENUE_URL}Events/deleteexpense/${e.expenseId}/${eventID}`,
            {
              method: "DELETE",
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            handleCallback(res.data);
            if (
              (res.data.statusCode === 200 ||
                res.data.statusCode === 201 ||
                res.data.statusCode === 202) &&
              i === memberList.length - 1
            ) {
              openDatabase().then((db) => {
                clearDeleteExpenditure(db, eventID);
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  //Syncing update member Data
  const syncOfflineUpdateMemberData = async (memberList) => {
    if (memberList?.length > 0) {
      memberList.map(async (e, i) => {
        await axios
          .post(
            `${configData.VENUE_URL}Member/${
              e?.isAnonymous
                ? "UpdateAnonymousMember"
                : "UpdateMembershipDetails"
            }`,
            JSON.stringify(e),
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            handleCallback(res.data);
            if (res.data.statusCode === 200 && i === memberList.length - 1) {
              openDatabase().then((db) => {
                clearUpdateMember(db);
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      openDatabase().then((db) => {
        //DEV
        if (offlineCreateAttendanceData.length > 0) {
          syncOfflineMemberCreateAttendance(offlineCreateAttendanceData);
        }
        if (offlineRecordAttendanceData.length > 0) {
          syncOfflineMemberRecordAttendance(offlineRecordAttendanceData);
        }

        if (offlineUpdateAttendanceData.length > 0) {
          syncOfflineMemberUpdateAttendance(offlineUpdateAttendanceData);
        }
        if (
          offlineUpdateAddExpenditureData.length > 0 &&
          singleCallFLag === 0
        ) {
          singleCallFLag = 1;
          syncOfflineAddExpense(offlineUpdateAddExpenditureData);
        }
        //DEV
        if (
          offlineUpdateMemberData.length > 0 &&
          offlineCreateAttendanceData.length < 1
        ) {
          syncOfflineUpdateMemberData(offlineUpdateMemberData);
        }
        if (deleteAttendanceData.length > 0) {
          syncOfflineDeleteAttendance(deleteAttendanceData);
        }
        if (offlineDeleteExpenseData.length > 0) {
          syncOfflineDeleteExpense(offlineDeleteExpenseData);
        }
      });
      setTimeout(() => {
        setSync(false);
      }, 3000);
    }

    setTimeout(() => {
      offlineDataCallback(false);
      addMemberSyncDataCallback(false);
    }, 10000);
  }, []);

  return <div></div>;
};

export default SyncRecordAttendanceData;

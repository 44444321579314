import axios from "axios";
import React from "react";
import { useEffect } from "react";
import configData from "./../../config.js";
import {
  openDatabase,
  ensureObjectStore,
  clearHomeEvents,
  createHomeEvents,
  clearActiveUsers,
  createActiveUsers,
  clearBookedCount,
  createBookedCount,
  clearBookingAmount,
  createBookingAmount,
  clearExpenseTypes,
  createExpenseTypes,
  clearEntryType,
  createEntryType,
  clearEventInfo,
  createEventInfo,
  clearExpenditure,
  createExpenditure,
  clearEventBookings,
  createEventBookings,
  clearEventAttendance,
  createEventAttendance,
} from "../db.js";

const StoringDataInDb = ({
  events,
  setDownloadDataInIndexedDB,
  setDataSynced,
  setOfflineMessage,
}) => {
  const token = localStorage.getItem("token");

  useEffect(() => {
    setDownloadDataInIndexedDB(false);

    openDatabase(undefined, undefined, (db, event) => {
      console.log("Upgrading database...");
      ensureObjectStore(db, "HomeEvents");
      ensureObjectStore(db, "AddMember");
      ensureObjectStore(db, "ActiveUsers");
      ensureObjectStore(db, "Expensetypes");
      ensureObjectStore(db, "UpdateMember");
      events?.forEach((event) => {
        ensureObjectStore(db, `EntryType${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        ensureObjectStore(db, `EventInfo${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        ensureObjectStore(db, `EventBookings${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        ensureObjectStore(db, `EventAttendance${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        ensureObjectStore(db, `RecordAttendance${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        ensureObjectStore(db, `CreateAttendance${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        ensureObjectStore(db, `UpdateAttendance${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        ensureObjectStore(db, `Expenditure${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        ensureObjectStore(db, `AddExpenditure${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        ensureObjectStore(db, `DeleteExpenditure${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        ensureObjectStore(db, `DeleteAttendance${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        ensureObjectStore(db, `BookedCount${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        ensureObjectStore(db, `BookingAmount${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        setDataSynced(true);
        setOfflineMessage("Events data available in offline mode");
      });
    });

    openDatabase().then((db) => {
      clearHomeEvents(db).then(() => {
        events.forEach(function (item, index) {
          item.id = index;
          createHomeEvents(db, item);
        });
      });
      setOfflineMessage("Events data available in offline mode");
    });

    //Storing ActiveUsers data in IndexDB
    console.log(
      "Storing ActiveUsers data in IndexDB",
      `${configData.VENUE_URL}Member/GetUserDataForOffline`
    );
    axios
      .get(`${configData.VENUE_URL}Member/GetUserDataForOffline`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data.payload;
      })
      .then((data) => {
        openDatabase().then((db) => {
          clearActiveUsers(db).then(() => {
            createActiveUsers(db, { activeUsers: data, id: 8 });
          });
        });
      })
      .catch(() => {
        //setDataDownloadedStatus(false)
      });

    //Storing event Counts
    events?.forEach((event) => {
      let eventID = event?.eventID;
      axios
        .post(
          `${configData.VENUE_URL}Member/GetBookedCountByEventId?eventid=${eventID}`,
          {},
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          openDatabase().then((db) => {
            clearBookedCount(db, eventID).then(() => {
              createBookedCount(db, eventID, {
                eventCount: data?.data?.payload,
                id: 8,
              });
            });
          });
        });

      axios
        .post(
          `${configData.VENUE_URL}Member/GetBookingAmountByEventId?eventid=${eventID}`,
          {},
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          openDatabase().then((db) => {
            clearBookingAmount(db, eventID).then(() => {
              createBookingAmount(db, eventID, {
                Booking: data?.data?.payload,
                id: 9,
              });
            });
          });
        });
    });
    //Storing expensetypes data in IndexDB
    axios
      .get(`${configData.VENUE_URL}Events/expensetypes`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data.payload;
      })
      .then((data) => {
        openDatabase().then((db) => {
          clearExpenseTypes(db).then(() => {
            createExpenseTypes(db, { expensetypes: data, id: 9 });
          });
        });
      })
      .catch(() => {
        //setDataDownloadedStatus(false)
      });

    // Storing EntryType data in IndexedDB
    events?.forEach((event) => {
      axios
        .get(
          `${configData.VENUE_URL}Events/
        ${event?.eventID}/getentrytypes`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data.payload;
        })
        .then((data) => {
          let eventID = event?.eventID;
          openDatabase().then((db) => {
            clearEntryType(db, eventID).then(() => {
              createEntryType(db, eventID, data);
            });
          });
        })
        .catch(() => {
          //setDataDownloadedStatus(false)
        });
    });

    // Storing the EventInfo data in indexedDB
    events?.forEach((event) => {
      axios
        .get(`${configData.MEMBER_URL}events/${event?.eventID}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          let eventId = data.result.eventID;
          let eventData = data.result;
          openDatabase().then((db) => {
            clearEventInfo(db, eventId).then(() => {
              createEventInfo(db, eventId, eventData);
            });
          });
        })
        .catch(() => {});
    });

    // Storing the Event Expenditures data in indexedDB
    events?.forEach((event) => {
      axios
        .get(`${configData.VENUE_URL}Events/${event?.eventID}/getexpenditure`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          let eventId = event?.eventID;
          let eventData = data.payload;
          openDatabase().then((db) => {
            clearExpenditure(db, eventId).then(() => {
              createExpenditure(db, eventId, { data: eventData, id: 8 });
            });
          });
        })
        .catch(() => {
          //setDataDownloadedStatus(false)
        });
    });

    // Storing the Booking and Attendance data in indexedDB
    events?.forEach((event) => {
      axios
        .get(
          `${configData.VENUE_URL}Attendance/${event?.eventID}/getattendance`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data.payload;
          //setDataDownloadedStatus(true);
        })
        .then((data) => {
          let bookingDetails = data.attendanceHistoryCount;
          let attendanceDetails = data.attendanceHistory;
          let eventId = event.eventID;

          openDatabase().then((db) => {
            clearEventBookings(db, eventId).then(() => {
              createEventBookings(db, eventId, bookingDetails);
            });
            clearEventAttendance(db, eventId).then(() => {
              attendanceDetails.forEach(function (item, index) {
                item.id = index;
                createEventAttendance(db, eventId, item);
              });
            });
          });
        })
        .catch(() => {});
    });
  }, []);

  return <div></div>;
};

export default StoringDataInDb;

import {
  Box,
  Drawer,
  IconButton,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import QrCodeScanner from "./QrCodeScanner";
import { useTranslation } from "react-i18next";
import getSymbolFromCurrency from "currency-symbol-map";
import configData from "./../../config.js";
import { createCreateAttendance } from "../db.js";

const RecordAttendance = ({
  recordAttendence,
  setRecordAttendence,
  eventID,
  eventTypeName,
  mode,
  callbackFunction,
}) => {
  const { t } = useTranslation();
  const [memberCardNumber, setMemberCardNumber] = useState("");
  const [entryType, setEntryType] = useState([]);
  const [selectedEntryType, setSelectedEntryType] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const token = localStorage.getItem("token");
  const [memberFoundStatus, setMemberFoundStatus] = useState();
  const [memberFoundMessage, setMemberFoundMessage] = useState("");
  const [priceOfEvent, setPriceOfEvent] = useState(0);
  const [entryTypeId, setEntryTypeId] = useState("");
  const memberId = localStorage.getItem("memberId");
  const [showCheckbox, setShowCheckbox] = useState(true);
  const [memberCardNumberError, setMemberCardNumberError] = useState();
  const [entryTypeError, setEntryTypeError] = useState();
  const [paymentModeError, setPaymentModeError] = useState();
  const [recordAttendanceSuccess] = useState(false);
  const [recordAttendenceSuccessMessage, setRecordAttendenceSuccessMessage] =
    useState("");
  const [apiStatus, setApiStatus] = useState();
  const [attendanceId, setAttendanceId] = useState("");
  const [attendanceError, setAttendanceError] = useState("");
  const [disablePaymentMode, setDisablePaymentMode] = useState(true);
  const [paymentPaid, setPaymentPaid] = useState(false);
  const navigate = useNavigate();

  //for QR Code
  const [qrCodeMemberId, setQrCodeMemberId] = useState("");
  const [, setMemberListForOffline] = useState([]);

  //for Currency Code
  const currencyCode = localStorage.getItem("currencyCode");
  const currencySymbol = getSymbolFromCurrency(currencyCode);

  useEffect(() => {
    if (eventID) {
      axios
        .get(
          `${configData.VENUE_URL}Events/
        ${eventID}/getentrytypes`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setEntryType(res.data.payload);
          return res.data.payload;
        })
        .catch((err) => console.log(err));
    }
  }, [eventID]);

  useEffect(() => {
    if (eventID) {
      axios
        .get(`${configData.VENUE_URL}Attendance/${eventID}/getattendance`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setMemberListForOffline(res.data.payload.attendanceHistory);
          return res.data.payload.attendanceHistory;
        })
        .catch((err) => console.log(err));
    }
  }, [eventID]);

  useEffect(() => {
    if (!recordAttendence) {
      setMemberCardNumber("");
      setSelectedEntryType("");
      setPaymentMode("");
      setMemberFoundStatus("");
      setMemberFoundMessage("");
      setRecordAttendenceSuccessMessage("");
    }
  }, [recordAttendence]);

  const fetchBookingAndAttendanceData = async () => {
    const res = await fetch(
      `${configData.VENUE_URL}Attendance/${eventID}/getattendance`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const handleRecordAttendance = () => {
    const recordAttendanceData = [
      {
        attendanceId: attendanceId,
        recordedBy: memberId,
      },
    ];

    axios
      .put(
        `${configData.VENUE_URL}Attendance/recordattendance`,
        JSON.stringify(recordAttendanceData),
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setShowCheckbox(true);
        setQrCodeMemberId("");
        //setRecordAttendenceSuccess(true);
        setApiStatus(res.data.payload);
        setRecordAttendenceSuccessMessage(res.data.payload);
        setMemberCardNumber("");
        setSelectedEntryType("");
        setPaymentMode("");
        setMemberFoundMessage("");
        setPriceOfEvent("");
        setMemberCardNumberError("");
        callbackFunction(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMemberCardNumber = () => {
    axios
      .get(`${configData.VENUE_URL}Member/${qrCodeMemberId}/getmember`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setMemberCardNumber(res.data.payload.memberCardNumber);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (qrCodeMemberId) {
      axios
        .get(
          `${configData.VENUE_URL}Attendance/getattendancebymember?EventId=${eventID}&MemberId=${qrCodeMemberId}`,
          //   JSON.stringify(memberData),
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          //setMemberFoundStatus(true);
          if (res.data.isSuccess === true) {
            setMemberFoundStatus(false);
            setMemberFoundMessage(null);
            // setShowCheckbox(false);
            if (res.data.payload.isEntryFeePaid === true) {
              setShowCheckbox(false);
              handleCreateAttendance();
            } else setShowCheckbox(true);
            setMemberCardNumber(res.data.payload.memberCardNumber);
            setPaymentMode(res.data.payload.paymentMethodName);
            //setSelectedEventType(res.data.payload.eventTypeName);
            setPriceOfEvent(res.data.payload.entryFeePaid);
            const retrieveEntryTypeID = entryType?.find(
              (entryTypeItem) =>
                entryTypeItem.entryTypeID === res.data.payload.entryTypeID
            );
            setSelectedEntryType(retrieveEntryTypeID);
            setAttendanceId(res.data.payload.attendanceID);

            // handleRecordAttendance();
          } else {
            getMemberCardNumber();
            setSelectedEntryType("");
            setPaymentMode("Cash");
            setMemberFoundStatus(true);
            setMemberFoundMessage(
              "Booking not found. Please select the values from the dropdown below"
            );
            setShowCheckbox(true);
          }
        })
        .catch(() => {
          setMemberFoundStatus(false);
          setMemberFoundMessage(
            "Entered card number doesn't exist. Please register the user from"
          );
        });
    }
    setMemberCardNumberError("");
    if (memberCardNumber) {
      axios
        .get(
          `${configData.VENUE_URL}Attendance/getattendancebymember?EventId=${eventID}&MemberCardNumber=${memberCardNumber}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.isSuccess === true) {
            setMemberFoundStatus(false);
            setMemberFoundMessage(null);
            setShowCheckbox(false);
            if (res.data.payload.isEntryFeePaid === true) {
              setShowCheckbox(false);
              setPaymentPaid(true);
            } else {
              setShowCheckbox(true);
            }
            setMemberCardNumber(res.data.payload.memberCardNumber);
            let selectedPaymentMode =
              res.data.payload.paymentMethodName === "cerditCard"
                ? "CreditCard"
                : res.data.payload.paymentMethodName;
            setPaymentMode(selectedPaymentMode);
            const retrieveEntryTypeID = entryType?.find(
              (entryTypeItem) =>
                entryTypeItem.entryTypeID === res.data.payload.entryTypeID
            );
            setSelectedEntryType(retrieveEntryTypeID.entryTypeID);
            let attendanceEventTypes = entryType?.filter(
              (entryTypeItem) =>
                entryTypeItem.entryFee >= res.data.payload.entryFeePaid
            );
            let amountPending =
              retrieveEntryTypeID.entryFee - res.data.payload.entryFeePaid;
            setPriceOfEvent(amountPending);
            setEntryType(attendanceEventTypes);
            setAttendanceId(res.data.payload.attendanceID);
          } else {
            setSelectedEntryType("");
            setPaymentMode("Cash");
            setMemberFoundStatus(true);
            setMemberFoundMessage(
              "Booking not found. Please select the values from the dropdown below"
            );
            setShowCheckbox(true);
            setDisablePaymentMode(false);
          }
        })
        .catch(() => {
          setMemberFoundStatus(false);
          setMemberFoundMessage(
            "Entered card number doesn't exist. Please register the user from"
          );
        });
    }
  }, [memberCardNumber, qrCodeMemberId]);

  useEffect(() => {
    if (selectedEntryType && !paymentPaid && !attendanceId) {
      const retrieveEntryType = entryType?.find(
        (entryTypeItem) => entryTypeItem.entryTypeID === selectedEntryType
      );
      setPriceOfEvent(retrieveEntryType.entryFee);
      setEntryTypeId(retrieveEntryType.entryTypeID);
    }
  }, [selectedEntryType]);

  const handleCreateAttendance = () => {
    setMemberCardNumberError("");
    setEntryTypeError("");
    setPaymentModeError("");
    if (memberCardNumber === "") {
      setMemberCardNumberError(`${t("Member Card Number is Required")}`);
    }

    if (selectedEntryType === "") {
      setEntryTypeError(`${t("Please Select Entry Type")}`);
    }

    if (paymentMode === "") {
      setPaymentModeError(`${t("Please Select Payment Mode")}`);
    }

    if (
      memberCardNumber === "" ||
      selectedEntryType === "" ||
      paymentMode === ""
    ) {
      return;
    }

    setEntryTypeError("");
    setPaymentModeError("");

    if (!showCheckbox) {
      handleRecordAttendance();
      return;
    }

    const createAttendanceData = [
      {
        EventID: eventID,
        MemberCardNumber: memberCardNumber * 1,
        EventTypeName: eventTypeName,
        EntryTypeID: entryTypeId,
        EntryFeePaid: priceOfEvent,
        PaymentMethodName: paymentMode,
        voucherNumber: null,
        notes: null,
        voucherSecurityCode: null,
        createdBy: memberId,
      },
    ];

    axios
      .post(
        `${configData.VENUE_URL}Attendance/createattendance`,
        JSON.stringify(createAttendanceData),
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (!res.data.isSuccess) {
          setRecordAttendenceSuccessMessage(res.data.errorMessage);
          if (res.data.status === 422) {
            res.data.validationErrors.map((error) => {
              switch (error.name) {
                case "EntryTypeID":
                  return setAttendanceError(error.reason);
                default:
                  return "";
              }
            });
          }
        } else {
          // setRecordAttendenceSuccess(true);
          setRecordAttendenceSuccessMessage("Attendance Recorded Successfully");
          setApiStatus(res.data.payload);
          callbackFunction(true);
          setMemberCardNumber("");
          // setSelectedEntryType("");
          setMemberFoundMessage("");
          // setPaymentMode("");
          setPriceOfEvent("");
          fetchBookingAndAttendanceData().then((data) => {
            if (data.isError) {
              navigate("/");
            }
            if (data.statusCode === 200) {
              const bookingAndAttendanceData = data.payload;
              if (bookingAndAttendanceData) {
                // setBooking(bookingAndAttendanceData.attendanceHistoryCount);
                // setAttendances(bookingAndAttendanceData.attendanceHistory);
              }
            }
          });
          openDatabase().then((db) => {
            createCreateAttendance(db, eventID, {
              id: res.data.responseIdentifier,
              EventID: eventID,
              MemberCardNumber: memberCardNumber * 1,
              EventTypeName: eventTypeName,
              EntryTypeID: entryTypeId,
              EntryFeePaid: priceOfEvent,
              PaymentMethodName: paymentMode,
              voucherNumber: null,
              notes: null,
              voucherSecurityCode: null,
              createdBy: memberId,
            });
          });
        }
        setMemberCardNumber("");
        setSelectedEntryType("");
        // setPaymentMode("");
        setPriceOfEvent("");
        setMemberCardNumberError("");
        setTimeout(() => {
          setRecordAttendenceSuccessMessage("");
        }, 5000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Drawer
        PaperProps={{
          sx: {
            width: "30%",
            height: "100vh",
          },
        }}
        anchor="right"
        open={recordAttendence}
        onClose={() => setRecordAttendence(false)}
      >
        {!recordAttendanceSuccess ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                m: "2rem",
              }}
            >
              <Typography>
                <IconButton onClick={() => setRecordAttendence(false)}>
                  <KeyboardBackspaceOutlinedIcon />
                </IconButton>
                {t("Record Attendance")}
              </Typography>
              {recordAttendenceSuccessMessage ? (
                <h6
                  className={
                    apiStatus && recordAttendenceSuccessMessage
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  {recordAttendenceSuccessMessage
                    ? recordAttendenceSuccessMessage + " !"
                    : ""}
                </h6>
              ) : (
                ""
              )}
              {attendanceError ? (
                <h6 className="text-danger mb-2">{attendanceError}</h6>
              ) : (
                ""
              )}
              <Box>
                {mode === "online" ? (
                  <QrCodeScanner
                    qrCodeMemberId={qrCodeMemberId}
                    setQrCodeMemberId={setQrCodeMemberId}
                    t={t}
                    recordAttendence={recordAttendence}
                  />
                ) : null}
              </Box>
              <TextField
                sx={{ width: "100%", mb: 0 }}
                size="small"
                label={t("Member Card Number")}
                value={memberCardNumber}
                onChange={(e) => setMemberCardNumber(e.target.value)}
              />
              {memberCardNumberError ? (
                <Typography
                  variant="paragraph"
                  sx={{ fontSize: "14px", color: "red" }}
                >
                  {memberCardNumberError}
                </Typography>
              ) : null}
              {memberFoundStatus ? (
                <>
                  <Typography
                    variant="paragraph"
                    sx={{ fontSize: "14px", color: "red" }}
                  >
                    {memberFoundMessage}
                  </Typography>
                </>
              ) : null}
              <FormControl sx={{ width: "100%" }} size="small">
                <InputLabel>{t("Entry Type")}</InputLabel>
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 40 * 4.5 + 4,
                      },
                    },
                  }}
                  label={t("Entry Type")}
                  value={selectedEntryType}
                  onChange={(e) => setSelectedEntryType(e.target.value)}
                >
                  {entryType?.map((entryTypeItem, index) => (
                    <MenuItem key={index} value={entryTypeItem.entryTypeID}>
                      {entryTypeItem.entryTypeName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {entryTypeError ? (
                <Typography
                  variant="paragraph"
                  sx={{ fontSize: "14px", color: "red" }}
                >
                  {entryTypeError}
                </Typography>
              ) : null}
              <FormControl sx={{ width: "100%" }} size="small">
                <InputLabel>{t("Payment Mode")}</InputLabel>
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 40 * 4.5 + 4,
                      },
                    },
                  }}
                  label={t("Payment Mode")}
                  value={paymentMode}
                  onChange={(e) => setPaymentMode(e.target.value)}
                  disabled={!priceOfEvent && disablePaymentMode ? true : false}
                >
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="MyPoS" selected>
                    MyPoS
                  </MenuItem>
                  <MenuItem value="CreditCard">CreditCard</MenuItem>
                  <MenuItem value="DebitCard">DebitCard</MenuItem>
                </Select>
              </FormControl>
              {paymentModeError ? (
                <Typography
                  variant="paragraph"
                  sx={{ fontSize: "14px", color: "red" }}
                >
                  {paymentModeError}
                </Typography>
              ) : null}
              {priceOfEvent ? (
                <Button
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    color: "orange",
                    m: "auto",
                    p: "0.5rem 0.5rem",
                    backgroundColor: "lightgrey",
                    "&:hover": {
                      backgroundColor: "lightgrey",
                      color: "orange",
                    },
                  }}
                >
                  {t("Pending Amount")}:&nbsp;&nbsp;
                  <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                    {currencySymbol}
                  </span>
                  &nbsp;
                  {priceOfEvent}
                </Button>
              ) : (
                <Button
                  sx={{
                    m: "auto",
                    width: "100%",
                    backgroundColor: "lightgray",
                    color: paymentPaid ? "green" : "red",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "lightgray",
                    },
                  }}
                >
                  {paymentPaid ? t("Payment Paid") : t("Pending Amount")}
                  {!paymentPaid ? ` : ${currencySymbol} 0` : ""}
                </Button>
              )}
            </Box>
            <Button
              sx={{
                textTransform: "none",
                width: "90%",
                backgroundColor: "#FFF1E6",
                m: "auto",
                mb: "0px",
                bottom: "1rem",
                color: "#F87B03",
                p: "0.5rem 0.5rem",
                "&:hover": {
                  backgroundColor: "#FFF1E6",
                },
              }}
              onClick={() => {
                navigate("/searchMember");
              }}
            >
              Find Member
            </Button>
            <Button
              sx={{
                textTransform: "none",
                width: "90%",
                backgroundColor: "#F87B03",
                m: "auto",
                mt: "10px",
                bottom: "1rem",
                color: "#fff",
                p: "0.5rem 0.5rem",
                "&:hover": {
                  backgroundColor: "#F87B03",
                },
              }}
              onClick={handleCreateAttendance}
            >
              {t("Submit")}
            </Button>
          </>
        ) : null}
      </Drawer>
    </div>
  );
};

export default RecordAttendance;

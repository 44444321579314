import {
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import QrCodeScanner from "./QrCodeScanner";
import { useTranslation } from "react-i18next";
import configData from "../../config.js";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import moment from "moment";
import { useLoader } from "../../components/SpinnerContext";

const FindMemberAttendance = ({
  recordAttendence,
  eventID,
  eventTypeName,
  selectedUserId,
  resetAttendanceFields,
  setResetAttendanceFields,
  setUpdateList,
  setEntryTypeData,
}) => {
  const { t } = useTranslation();
  const { setIsLoading } = useLoader();
  const [memberCardNumber, setMemberCardNumber] = useState("");
  const [memberFullName, setMemberFullName] = useState("");
  const [entryType, setEntryType] = useState([]);
  const [selectedEntryType, setSelectedEntryType] = useState([]);
  const [paymentMode, setPaymentMode] = useState(["Card"]);
  const token = localStorage.getItem("token");
  const [memberFoundStatus, setMemberFoundStatus] = useState();
  const [memberFoundMessage, setMemberFoundMessage] = useState("");
  const [priceOfEvent, setPriceOfEvent] = useState(0);
  const [, setEntryTypeId] = useState("");
  const memberId = localStorage.getItem("memberId");
  const [, setShowCheckbox] = useState(true);
  const [memberCardNumberError, setMemberCardNumberError] = useState();
  const [memberNameError, setMemberNameError] = useState();
  const [entryTypeError, setEntryTypeError] = useState();
  const [, setPaymentModeError] = useState();
  const [recordAttendanceSuccess] = useState(false);
  const [recordAttendenceSuccessMessage, setRecordAttendenceSuccessMessage] =
    useState("");
  const [apiStatus, setApiStatus] = useState();
  const [attendanceId, setAttendanceId] = useState("");
  const [attendanceError, setAttendanceError] = useState("");
  const [paymentPaid, setPaymentPaid] = useState(false);
  const navigate = useNavigate();

  //for QR Code
  const [qrCodeMemberId, setQrCodeMemberId] = useState("");
  const [, setMemberListForOffline] = useState([]);
  const [scanQRPressed, setScanQRPressed] = useState(false);
  const [userType, setUserType] = useState("");

  //for Currency Code
  const [event, setEvent] = useState("");
  const textFieldForMemberIdRef = React.useRef(null);
  const textFieldForFullnameRef = React.useRef(null);
  const newBtnRef = React.useRef(null);
  const visitorBtnRef = React.useRef(null);
  const scanQrBtnRef = React.useRef(null);
  const submitBtnRef = React.useRef(null);
  const entryTypeRef = React.useRef(null);
  const paymentTypeRef = React.useRef(null);
  const modalBtnRef = React.useRef(null);
  const [show, setShow] = useState(false);
  const [userEfeData, setUserEfeData] = useState();
  const [emailAlert, setEmailAlert] = useState(false);
  const [elementRefreshCounter, setElementRefreshCounter] = useState(0);

  const handleClose = () => {
    setShow(false);
  };

  const handleCloseAlert = () => {
    setEmailAlert(false);
    if (memberCardNumberError) {
      newBtnRef.current.focus();
    } else {
      entryTypeRef.current.focus();
    }
  };

  useEffect(() => {
    if (eventID) {
      setEvent(eventID);
    }
  }, [eventID]);

  useEffect(() => {
    if (resetAttendanceFields) {
      setMemberCardNumber("");
      textFieldForMemberIdRef.current.focus();
      setMemberFullName("");
      defaultEntryType();
      setPaymentMode(["Card"]);
      setMemberFoundStatus("");
      setMemberFoundMessage("");
      setRecordAttendenceSuccessMessage("");
      setResetAttendanceFields(false);
    }
  }, [resetAttendanceFields]);

  useEffect(() => {
    if (event) {
      axios
        .get(
          `${configData.VENUE_URL}Events/
        ${event}/getentrytypes`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setEntryType(res.data.payload);
          return res.data.payload;
        })
        .catch((err) => console.log(err));
    }
  }, [event]);

  useEffect(() => {
    if (selectedUserId) {
      handleMemberNumberChange(
        selectedUserId.membershipCard
          ? selectedUserId.membershipCard
          : selectedUserId?.memberCardNumber
      );
      setMemberFullName(
        selectedUserId?.firstName
          ? `${selectedUserId?.firstName} ${selectedUserId?.lastName}`
          : selectedUserId?.fullName
      );
      if (selectedUserId?.entryTypeName) {
        defaultEntryType(selectedUserId?.entryTypeName);
      }
      if (selectedUserId?.paymentMode) {
        setPaymentMode([selectedUserId?.paymentMode]);
      }
    }
  }, [selectedUserId]);

  useEffect(() => {
    if (event) {
      axios
        .get(`${configData.VENUE_URL}Attendance/${event}/getattendance`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setMemberListForOffline(res.data.payload.attendanceHistory);
          return res.data.payload.attendanceHistory;
        })
        .catch((err) => console.log(err));
    }
  }, [event]);

  useEffect(() => {
    if (!recordAttendence) {
      setMemberCardNumber("");
      textFieldForMemberIdRef.current.focus();
      setMemberFullName("");
      defaultEntryType();
      setPaymentMode(["Card"]);
      setMemberFoundStatus("");
      setMemberFoundMessage("");
      setRecordAttendenceSuccessMessage("");
    }
  }, [recordAttendence]);

  const fetchBookingAndAttendanceData = async () => {
    const res = await fetch(
      `${configData.VENUE_URL}Attendance/${event}/getattendance`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const defaultEntryType = (val = "Standard Entry") => {
    const ans = entryType.find((e) => e?.entryTypeName.includes(val));
    if (ans) {
      setSelectedEntryType([ans.entryTypeID]);
      if (
        ans.entryTypeID &&
        !paymentPaid &&
        !attendanceId &&
        recordAttendenceSuccessMessage !== "MEMBER ALREADY ENTERED"
      ) {
        const retrieveEntryType = entryType?.find(
          (entryTypeItem) => entryTypeItem.entryTypeID === ans.entryTypeID
        );
        setPriceOfEvent(retrieveEntryType.entryFee);
        setEntryTypeId(retrieveEntryType.entryTypeID);
      } else {
        setPriceOfEvent(0);
      }
    }
  };

  useEffect(() => {
    if (entryType) {
      defaultEntryType();
      setEntryTypeData(entryType);
    }
  }, [entryType]);

  useEffect(() => {
    if (qrCodeMemberId) {
      axios
        .get(
          `${configData.VENUE_URL}Attendance/getattendancebymember?EventId=${event}&MemberId=${qrCodeMemberId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.payload) {
            setMemberFullName(
              res.data.payload?.firstName
                ? `${res.data.payload?.firstName} ${res.data.payload?.lastName}`
                : res.data.payload?.fullName
            );
            if (res.data.payload?.isAttended) {
              setRecordAttendenceSuccessMessage("MEMBER ALREADY ENTERED");
            }
          } else {
            setMemberFullName("");
          }
          if (res.data.isSuccess === true) {
            setMemberFoundStatus(false);
            setMemberFoundMessage(null);
            if (res.data.payload.isEntryFeePaid === true) {
              setShowCheckbox(false);
              handleCreateAttendance();
            } else setShowCheckbox(true);
            setMemberCardNumber(res.data.payload.memberCardNumber);
            setPaymentMode(res.data.payload.paymentMethodName);
            setPriceOfEvent(res.data.payload.entryFeePaid);
            const retrieveEntryTypeID = entryType?.find(
              (entryTypeItem) =>
                entryTypeItem.entryTypeID === res.data.payload.entryTypeID
            );
            setSelectedEntryType([retrieveEntryTypeID.entryTypeID]);
            setAttendanceId(res.data.payload.attendanceID);
          } else {
            const retrieveEntryType = entryType?.find(
              (entryTypeItem) =>
                entryTypeItem.entryTypeID === selectedEntryType[0]
            );
            setPriceOfEvent(retrieveEntryType.entryFee);
            setPaymentMode(["Card"]);
            setPaymentPaid(false);
          }
        })
        .catch(() => {
          setMemberFoundStatus(false);
          setMemberFullName("");
          setMemberFoundMessage("Member Number not recognised");
          setTimeout(() => {
            newBtnRef.current.focus();
          }, 300);
        });
    }
    setMemberCardNumberError("");
    setUserType("");
  }, [qrCodeMemberId]);

  const getMemberInfo = () => {
    if (memberCardNumber && memberCardNumber.length > 3) {
      setIsLoading(true);
      axios
        .get(
          `${configData.VENUE_URL}Attendance/getattendancebymember?EventId=${event}&MemberCardNumber=${memberCardNumber}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setIsLoading(false);
          if (res.data.payload) {
            setMemberFullName(
              res.data.payload?.firstName
                ? `${res.data.payload?.firstName} ${res.data.payload?.lastName}`
                : res.data.payload?.fullName
            );
            setEmailAlert(
              res.data.payload?.email?.length < 1 ||
                res.data.payload?.email?.includes("ceroc-member-without-email")
                ? true
                : false
            );
            if (
              res.data.payload?.isAttended ||
              (res.data?.errorMessage === "" && res.data?.isSuccess)
            ) {
              setRecordAttendenceSuccessMessage("MEMBER ALREADY ENTERED");
              setElementRefreshCounter(elementRefreshCounter + 1);
            } else {
              setRecordAttendenceSuccessMessage("");
            }
          } else {
            setRecordAttendenceSuccessMessage("");
            setMemberFullName("");
            setUserEfeData();
          }
          if (res.data.isSuccess === true) {
            setMemberFoundStatus(false);
            setMemberFoundMessage(null);
            setShowCheckbox(false);
            if (res.data.payload.isEntryFeePaid === true) {
              setShowCheckbox(false);
              setPaymentPaid(true);
            } else {
              setShowCheckbox(true);
            }
            setMemberCardNumber(res.data.payload.memberCardNumber);
            let selectedPaymentMode =
              res.data.payload.paymentMethodName === "cerditCard"
                ? "Card"
                : res.data.payload.paymentMethodName;
            setPaymentMode(selectedPaymentMode);
            const retrieveEntryTypeID = entryType?.find(
              (entryTypeItem, index) =>
                entryTypeItem.entryTypeID === res.data.payload.entryTypeID
            );
            let amountPending =
              retrieveEntryTypeID?.entryFee - res.data.payload.entryFeePaid;
            setPriceOfEvent(amountPending);
            setAttendanceId(res.data.payload.attendanceID);
          } else {
            const retrieveEntryType = entryType?.find(
              (entryTypeItem) =>
                entryTypeItem.entryTypeID === selectedEntryType[0]
            );
            setPriceOfEvent(retrieveEntryType?.entryFee);
            setPaymentMode(["Card"]);
            setPaymentPaid(false);
          }
        })
        .catch((err) => {
          verifyUser();
          setMemberFoundStatus(false);
          setMemberFullName("");
          setRecordAttendenceSuccessMessage("");
          setUserEfeData();
          setEmailAlert(false);
          setMemberFoundMessage("Member Number not recognised");
        });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (memberFullName.length > 1 && userType.length < 1) {
      axios
        .get(
          `${configData.VENUE_URL}Attendance/EFEExists?memberCardNumber=${memberCardNumber}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res?.data?.payload) {
            let data = JSON.parse(res.data.payload);
            setUserEfeData(data);
            setShow(true);
          }
        });
    }
  }, [memberFullName]);

  useEffect(() => {
    if (selectedEntryType[0] && !paymentPaid && !attendanceId) {
      const retrieveEntryType = entryType?.find(
        (entryTypeItem) => entryTypeItem.entryTypeID === selectedEntryType[0]
      );
      setPriceOfEvent(retrieveEntryType.entryFee);
      setEntryTypeId(retrieveEntryType.entryTypeID);
    }
  }, [selectedEntryType]);

  const handleCreateAttendance = () => {
    setMemberCardNumberError("");
    setEntryTypeError("");
    setPaymentModeError("");
    setMemberNameError("");
    setMemberFoundMessage("");
    if (memberCardNumber === "") {
      setMemberCardNumberError(`${t("Member Card Number is Required")}`);
    } else {
      setMemberCardNumberError("");
    }

    if (selectedEntryType.length === 0) {
      setEntryTypeError(`${t("Please Select Entry Type")}`);
    } else {
      setEntryTypeError("");
    }

    if (paymentMode === "") {
      setPaymentModeError(`${t("Please Select Payment Mode")}`);
    } else {
      setPaymentModeError("");
    }

    if (memberFullName === "") {
      if (userType && userType.length > 1) {
        setMemberNameError("");
        setMemberFullName(userType);
      } else {
        setMemberNameError(`${t("Please Enter Valid Name")}`);
      }
    }

    if (
      memberCardNumber === "" ||
      selectedEntryType.length === 0 ||
      paymentMode === "" ||
      (memberFullName === "" && userType.length < 1)
    ) {
      return;
    }

    setEntryTypeError("");
    setPaymentModeError("");
    let createAttendanceData = [
      {
        EventID: event,
        MemberCardNumber: memberCardNumber * 1,
        EventTypeName: eventTypeName,
        entryTypeID: selectedEntryType[0],
        EntryFeePaid: priceOfEvent,
        PaymentMethodName: paymentMode[0],
        voucherNumber: null,
        notes: null,
        voucherSecurityCode: null,
        createdBy: memberId,
        isAnonymous: userType && userType.length > 0 ? true : false,
        name: userType,
      },
    ];
    if (userEfeData?.EFEAssignedID) {
      createAttendanceData[0].efeAssignedID = userEfeData?.EFEAssignedID;
      axios
        .post(
          `${configData.VENUE_URL}Attendance/createattendanceEFE`,
          JSON.stringify(createAttendanceData),
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (!res.data.isSuccess) {
            setRecordAttendenceSuccessMessage(res.data.errorMessage);
            if (res.data.status === 422) {
              res.data.validationErrors.map((error) => {
                switch (error.name) {
                  case "entryTypeID":
                    return setAttendanceError(error.reason);
                  default:
                    return "";
                }
              });
            }
          } else {
            setEmailAlert(false);
            setUpdateList(true);
            setRecordAttendenceSuccessMessage("MEMBER ADMITTED THROUGH EFE");
            setElementRefreshCounter(elementRefreshCounter + 1);
            setTimeout(() => {
              setRecordAttendenceSuccessMessage("");
            }, 4000);
            setApiStatus(res.data.payload);
            setMemberCardNumber("");
            textFieldForMemberIdRef.current.focus();
            setMemberFullName("");
            defaultEntryType();
            setMemberFoundMessage("");
            setPaymentMode(["Card"]);
            setPriceOfEvent(0);
            fetchBookingAndAttendanceData().then((data) => {
              if (data.isError) {
                navigate("/");
              }
              if (data.statusCode === 200) {
                const bookingAndAttendanceData = data.payload;
                if (bookingAndAttendanceData) {
                }
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(
          `${configData.VENUE_URL}Attendance/createattendance`,
          JSON.stringify(createAttendanceData),
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (!res.data.isSuccess) {
            setRecordAttendenceSuccessMessage(res.data.errorMessage);
            if (res.data.status === 422) {
              res.data.validationErrors.map((error) => {
                switch (error.name) {
                  case "entryTypeID":
                    return setAttendanceError(error.reason);
                  default:
                    return "";
                }
              });
            }
          } else {
            setUpdateList(true);
            setRecordAttendenceSuccessMessage("MEMBER ADMITTED");
            setElementRefreshCounter(elementRefreshCounter + 1);
            setTimeout(() => {
              setRecordAttendenceSuccessMessage("");
            }, 4000);
            setApiStatus(res.data.payload);
            setMemberCardNumber("");
            setMemberFullName("");
            setUserType("");
            textFieldForMemberIdRef.current.focus();
            defaultEntryType();
            setMemberFoundMessage("");
            setPaymentMode(["Card"]);
            setPriceOfEvent(0);
            fetchBookingAndAttendanceData().then((data) => {
              if (data.isError) {
                navigate("/");
              }
              if (data.statusCode === 200) {
                const bookingAndAttendanceData = data.payload;
                if (bookingAndAttendanceData) {
                }
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChangePaymentMode = (event) => {
    const { options } = event.target;
    let value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPaymentMode(value);
  };

  const handleChangeEventTypeMode = (event) => {
    const { options } = event.target;
    let value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedEntryType(value);
  };

  const renderEFEModal = () => {
    if (show) {
      return (
        <div
          className="modal modalOffline show"
          style={{
            display: "block",
            margin: "3rem 0 0 0rem",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          }}
        >
          <Modal.Dialog
            className="offlineData"
            sx={{ margin: "9rem 0 0 0rem" }}
          >
            <Modal.Header>
              <Modal.Title>EFE is available</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form>
                <div style={{ display: "flex" }}>
                  <Form.Group className="mb-3" style={{ flex: "4" }}>
                    <Form.Label>EFE Assigned ID</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={userEfeData?.EFEAssignedID}
                      autoFocus
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    style={{ marginLeft: "1rem", marginRight: "1.2rem" }}
                  >
                    <Form.Label>Entry Type Name</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={userEfeData?.EntryTypeName}
                      autoFocus
                    />
                  </Form.Group>
                </div>
                <div style={{ display: "flex" }}>
                  <Form.Group className="mb-3">
                    <Form.Label>Max Events to Attends</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={userEfeData?.MaxAttends}
                      autoFocus
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" style={{ marginLeft: "4rem" }}>
                    <Form.Label>Remaining Attendance</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={userEfeData?.RemainingAttendance}
                      autoFocus
                    />
                  </Form.Group>
                </div>
                <div style={{ display: "flex" }}>
                  <Form.Group className="mb-3">
                    <Form.Label>ValidFrom</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={moment(userEfeData?.ValidFrom).format(
                        "DD/MM/YYYY  HH:mm"
                      )}
                      autoFocus
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" style={{ marginLeft: "4rem" }}>
                    <Form.Label>ValidTo</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={moment(userEfeData?.ValidTo).format(
                        "DD/MM/YYYY  HH:mm"
                      )}
                      autoFocus
                    />
                  </Form.Group>
                </div>
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => handleClose()}>
                Close
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      );
    }
  };

  const renderEmailAlert = () => {
    if (emailAlert) {
      setTimeout(() => {
        modalBtnRef.current.focus();
      }, 500);
      return (
        <div
          className="modal modalOffline show"
          style={{
            display: "block",
            margin: "3rem 0 0 0rem",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          }}
        >
          <Modal.Dialog
            className="offlineData"
            sx={{ margin: "9rem 0 0 0rem" }}
          >
            <Modal.Header>
              <Modal.Title className="email-alert">
                NO EMAIL ADDRESS.
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <h5 className="email-alert">
                Please ask the member for an email address and update their
                record.
              </h5>
            </Modal.Body>

            <Modal.Footer>
              <Button
                ref={modalBtnRef}
                variant="secondary"
                onClick={() => handleCloseAlert()}
              >
                Okay
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      );
    }
  };

  const verifyUser = () => {
    if (memberCardNumber && memberCardNumber.length > 3) {
      axios
        .get(
          `${configData.VENUE_URL}Attendance/getattendancebymember?EventId=${event}&MemberCardNumber=${memberCardNumber}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          setMemberCardNumberError("");
          setUserType("");
        })
        .catch(() => {
          setMemberCardNumberError("Member Number not recognised");
          setTimeout(() => {
            newBtnRef.current.focus();
          }, 300);
        });
    } else {
      setMemberCardNumberError("");
      setUserType("");
    }
  };

  const handleMemberNumberChange = (val) => {
    setMemberCardNumber(val);
    setMemberCardNumberError("");
    setUserType("");
    setMemberFoundMessage("");
    setRecordAttendenceSuccessMessage("");
    setMemberFullName("");
  };

  return (
    <div>
      {!recordAttendanceSuccess ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              mt: "3rem",
              mr: "4px",
            }}
          >
            {recordAttendenceSuccessMessage ? (
              <h5
                className={
                  apiStatus &&
                  recordAttendenceSuccessMessage &&
                  (recordAttendenceSuccessMessage === "MEMBER ADMITTED" ||
                    recordAttendenceSuccessMessage ===
                      "MEMBER ADMITTED THROUGH EFE")
                    ? "text-success"
                    : "text-danger"
                }
              >
                {recordAttendenceSuccessMessage &&
                (recordAttendenceSuccessMessage === "MEMBER ADMITTED" ||
                  recordAttendenceSuccessMessage ===
                    "MEMBER ADMITTED THROUGH EFE")
                  ? recordAttendenceSuccessMessage + " !"
                  : "MEMBER ALREADY ENTERED"}
              </h5>
            ) : (
              ""
            )}
            {attendanceError ? (
              <h5 className="text-danger mb-2">{attendanceError}</h5>
            ) : (
              ""
            )}
            <div style={{ display: "flex" }}>
              <div
                className="form-floating mb-2"
                style={{ width: "100%", flex: "3" }}
              >
                <input
                  type="text"
                  id="memberCardNumber"
                  className="form-control"
                  style={{ fontSize: "37px" }}
                  onChange={(e) => handleMemberNumberChange(e.target.value)}
                  value={memberCardNumber}
                  ref={textFieldForMemberIdRef}
                  onKeyDown={(event) => {
                    const { key } = event;
                    if (key === "Enter") {
                      if (memberCardNumber) {
                        getMemberInfo();
                        setTimeout(() => {
                          if (memberCardNumberError?.length === 0) {
                            entryTypeRef.current.focus();
                          }
                        }, 800);
                      } else if (!memberCardNumber) {
                        scanQrBtnRef.current.focus();
                      } else if (memberCardNumber && !memberFullName) {
                        textFieldForMemberIdRef.current.blur();
                      }
                    }
                  }}
                  placeholder="Member Card Number"
                  data-1p-ignore="true"
                />
                <label htmlFor="floatingInput">
                  {memberCardNumber ? "" : t("Member Card Number")}
                </label>
              </div>
              <Button
                tabIndex={memberCardNumber ? -1 : 0}
                ref={scanQrBtnRef}
                onKeyDown={(event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    textFieldForFullnameRef.current.focus();
                  }
                }}
                sx={{
                  textTransform: "none",
                  width: "90%",
                  backgroundColor: "#FFF1E6",
                  color: "#F87B03",
                  flex: "1",
                  p: "0.5rem 0.5rem",
                  height: "3.7rem",
                  ml: "10px",
                  "&:hover": {
                    backgroundColor: "#FFF1E6",
                  },
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setScanQRPressed(true);
                }}
              >
                Scan QR
              </Button>
            </div>
            {memberCardNumberError ? (
              <>
                {!userType && (
                  <Typography
                    variant="paragraph"
                    sx={{ fontSize: "14px", color: "red", mt: "-20px" }}
                  >
                    {memberCardNumberError}
                  </Typography>
                )}
                <div style={{ display: "flex" }}>
                  <Button
                    sx={{
                      textTransform: "none",
                      borderRadius: "1rem",
                      fontWeight: "915",
                    }}
                    onClick={() => setUserType("New")}
                    style={{
                      border: "3px solid #fd7e14",
                      backgroundColor: `${
                        userType === "New" ? "#fd7e14" : "#fff"
                      }`,
                      color: `${userType === "New" ? "#fff" : "black"}`,
                    }}
                    variant="contained"
                    ref={newBtnRef}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowRight") {
                        visitorBtnRef.current.focus();
                      } else if (e.key === "ArrowLeft") {
                        visitorBtnRef.current.focus();
                      } else if (e.key === "Enter") {
                        setUserType("New");
                        entryTypeRef.current.focus();
                      }
                    }}
                  >
                    New
                  </Button>
                  <Button
                    sx={{
                      textTransform: "none",
                      borderRadius: "1rem",
                      marginLeft: "1.5rem",
                      fontWeight: "915",
                    }}
                    onClick={() => setUserType("Visitor")}
                    style={{
                      border: "3px solid #fd7e14",
                      backgroundColor: `${
                        userType === "Visitor" ? "#fd7e14" : "#fff"
                      }`,
                      color: `${userType === "Visitor" ? "#fff" : "black"}`,
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowRight") {
                        newBtnRef.current.focus();
                      } else if (e.key === "ArrowLeft") {
                        newBtnRef.current.focus();
                      } else if (e.key === "Enter") {
                        setUserType("Visitor");
                        entryTypeRef.current.focus();
                      }
                    }}
                    variant="contained"
                    ref={visitorBtnRef}
                  >
                    Visitor
                  </Button>
                </div>
              </>
            ) : null}
            <div className="form-floating mb-2" style={{ width: "100%" }}>
              <input
                type="text"
                className="form-control"
                disabled={true}
                onChange={(e) => setMemberFullName(e.target.value)}
                ref={textFieldForFullnameRef}
                onKeyDown={(event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    entryTypeRef.current.focus();
                  }
                }}
                value={userType ? userType : memberFullName}
                placeholder="Full Name"
              />
              <label htmlFor="floatingInput">{t("Full Name")}</label>
            </div>
            {memberNameError ? (
              <Typography
                variant="paragraph"
                sx={{ fontSize: "14px", color: "red" }}
              >
                {memberNameError}
              </Typography>
            ) : null}
            {memberFoundStatus ? (
              <>
                <Typography
                  variant="paragraph"
                  sx={{ fontSize: "14px", color: "red" }}
                >
                  {memberFoundMessage}
                </Typography>
              </>
            ) : null}
            <FormControl sx={{ width: "100%" }}>
              <InputLabel shrink htmlFor="entryType">
                Entry Type
              </InputLabel>
              <Select
                key={elementRefreshCounter}
                inputRef={entryTypeRef}
                onKeyDown={(event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    if (priceOfEvent > 1) {
                      paymentTypeRef.current.focus();
                    } else {
                      submitBtnRef.current.click();
                    }
                  }
                }}
                multiple
                native
                value={selectedEntryType}
                onChange={(e) => handleChangeEventTypeMode(e)}
                label="Entry Type"
                inputProps={{
                  id: "entryType",
                }}
              >
                {entryType?.map((entryTypeItem, index) => (
                  <option
                    className="fi-at-ent"
                    key={index}
                    value={entryTypeItem.entryTypeID}
                  >
                    {`${entryTypeItem.entryTypeName} £${entryTypeItem.entryFee}`}
                  </option>
                ))}
              </Select>
            </FormControl>
            {entryTypeError ? (
              <Typography
                variant="paragraph"
                sx={{ fontSize: "14px", color: "red" }}
              >
                {entryTypeError}
              </Typography>
            ) : null}
            <FormControl sx={{ width: "100%", mt: "10px" }}>
              <InputLabel shrink htmlFor="paymentMode">
                Payment Mode
              </InputLabel>
              <Select
                inputRef={paymentTypeRef}
                key={elementRefreshCounter}
                onKeyDown={(event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    submitBtnRef.current.click();
                  }
                }}
                multiple
                disabled={priceOfEvent < 1}
                native
                value={paymentMode}
                onChange={(e) => handleChangePaymentMode(e)}
                label="Payment Mode"
                inputProps={{
                  id: "paymentMode",
                }}
              >
                <option className="fi-at-opt" value="Card">
                  Card
                </option>
                <option className="fi-at-opt" value="Cash">
                  Cash
                </option>
                <option className="fi-at-opt" value="Online">
                  Online
                </option>
              </Select>
            </FormControl>
            <div style={{ marginTop: "40px" }}>
              <div
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Button
                  id="submitAttendance"
                  ref={submitBtnRef}
                  sx={{
                    textTransform: "none",
                    width: "80%",
                    backgroundColor: "#F87B03",
                    m: "auto",
                    mt: "10px",
                    bottom: "1rem",
                    color: "#fff",
                    p: "0.5rem 0.5rem",
                    "&:hover": {
                      backgroundColor: "#F87B03",
                    },
                  }}
                  onClick={handleCreateAttendance}
                >
                  {t("Submit")}
                </Button>
              </div>
            </div>
            {scanQRPressed && (
              <div
                className="modal modalOffline show"
                style={{
                  display: "block",
                  margin: "3rem 0 0 0rem",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                }}
              >
                <Modal.Dialog
                  className="offlineData"
                  sx={{ margin: "9rem 0 0 0rem" }}
                >
                  <Modal.Body>
                    <h5>Scan the QR</h5>
                    <QrCodeScanner
                      qrCodeMemberId={qrCodeMemberId}
                      setQrCodeMemberId={setQrCodeMemberId}
                      t={t}
                      recordAttendence={recordAttendence}
                    />
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setScanQRPressed(false)}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal.Dialog>
              </div>
            )}
          </Box>
        </>
      ) : null}
      {renderEFEModal()}
      {renderEmailAlert()}
    </div>
  );
};

export default FindMemberAttendance;

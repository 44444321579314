import {
  Box,
  Drawer,
  IconButton,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useState } from "react";
import { useEffect } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  openDatabase,
  fetchEntryType,
  fetchEventAttendance,
  createRecordAttendance,
  createCreateAttendance,
} from "../db.js";

const RecordAttendanceOffline = ({
  recordAttendence,
  setRecordAttendence,
  eventID,
  eventTypeName,
  t,
  mode,
  offlineDataCallback,
}) => {
  //const t = useTranslation();
  const [memberCardNumber, setMemberCardNumber] = useState("");
  const [entryType, setEntryType] = useState([]);
  const [selectedEntryType, setSelectedEntryType] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [memberFoundStatus] = useState();
  const [memberFoundMessage] = useState("");
  const [priceOfEvent, setPriceOfEvent] = useState(0);
  const [entryTypeId, setEntryTypeId] = useState("");
  const memberId = localStorage.getItem("memberId");
  const [memberCardNumberError, setMemberCardNumberError] = useState();
  const [entryTypeError, setEntryTypeError] = useState();
  const [paymentModeError, setPaymentModeError] = useState();
  const [recordAttendanceSuccess] = useState(false);
  const [recordAttendenceSuccessMessage, setRecordAttendenceSuccessMessage] =
    useState("");
  const [attendanceId, setAttendanceId] = useState("");
  const [attendanceError] = useState("");
  const [paymentPaid] = useState(false);

  //for Currency Code
  const currencyCode = localStorage.getItem("currencyCode");
  const currencySymbol = getSymbolFromCurrency(currencyCode);

  useEffect(() => {
    if (mode === "offline") {
      openDatabase().then((db) => {
        fetchEntryType(db, eventID)
          .then((data) => {
            let entryTypes = data[0];
            setEntryType(entryTypes);
          })
          .catch((error) => {});
      });
    }
  }, []);

  useEffect(() => {
    if (mode === "offline") {
      //for Prebooked MemberList
      openDatabase().then((db) => {
        fetchEventAttendance(db, eventID)
          .then((data) => {
            // setMemberListForOffline(event.target.result);
            if (memberCardNumber) {
              data?.forEach((member) => {
                if (member.memberCardNumber === memberCardNumber) {
                  const retrieveEntryTypeID = entryType?.find(
                    (entryTypeItem) =>
                      entryTypeItem.entryTypeName === member.entryTypeName
                  );
                  let amountPending =
                    retrieveEntryTypeID.entryFee - member.entryFeePaid;
                  setPriceOfEvent(amountPending);
                  setSelectedEntryType(retrieveEntryTypeID);
                  setAttendanceId(member.attendanceID);
                  let selectedPaymentMode =
                    member.paymentMethodName === "cerditCard"
                      ? "CreditCard"
                      : member.paymentMethodName;

                  setPaymentMode(selectedPaymentMode);
                  handleRecordAttendance(member.attendanceID);
                }
              });
            }
          })
          .catch((error) => {});
      });
      return;
    }
  }, [memberCardNumber]);

  const handleRecordAttendance = (attendanceId) => {
    if (mode === "offline") {
      openDatabase().then((db) => {
        createRecordAttendance(db, eventID, {
          attendanceId: attendanceId,
          recordedBy: memberId,
        });
        offlineDataCallback(true);
        setRecordAttendenceSuccessMessage("Offline Atttendance Recorded");
        setMemberCardNumber("");
        setSelectedEntryType("");
        setPriceOfEvent(0);
        setTimeout(() => {
          setRecordAttendenceSuccessMessage("");
        }, 3000);
      });
      return;
    }
  };

  useEffect(() => {
    if (selectedEntryType && !paymentPaid && !attendanceId) {
      const retrieveEntryType = entryType?.find(
        (entryTypeItem) => entryTypeItem.entryTypeID === selectedEntryType
      );
      setPriceOfEvent(retrieveEntryType.entryFee);
      setEntryTypeId(retrieveEntryType.entryTypeID);
    } else {
      if (selectedEntryType) {
        const retrieveEntryType = entryType?.find(
          (entryTypeItem) => entryTypeItem.entryTypeID === selectedEntryType
        );
        if (retrieveEntryType) {
          setPriceOfEvent(retrieveEntryType.entryFee);
          setEntryTypeId(retrieveEntryType.entryTypeID);
        }
      }
    }
  }, [selectedEntryType]);

  const handleCreateAttendance = () => {
    setMemberCardNumberError("");
    setEntryTypeError("");
    setPaymentModeError("");
    if (memberCardNumber === "") {
      setMemberCardNumberError(`${t("Member Card Number is Required")}`);
    }

    if (selectedEntryType === "") {
      setEntryTypeError(`${t("Please Select Entry Type")}`);
    }

    if (paymentMode === "") {
      setPaymentModeError(`${t("Please Select Payment Mode")}`);
    }

    if (
      memberCardNumber === "" ||
      selectedEntryType === "" ||
      paymentMode === ""
    ) {
      return;
    }

    openDatabase().then((db) => {
      createCreateAttendance(db, eventID, {
        EventID: eventID,
        memberCardNumber: memberCardNumber * 1,
        EventTypeName: eventTypeName,
        entryTypeID: entryTypeId,
        EntryFeePaid: priceOfEvent,
        paymentMethodName: paymentMode,
        voucherNumber: null,
        notes: null,
        voucherSecurityCode: null,
        createdBy: memberId,
      });
      offlineDataCallback(true);
      setRecordAttendenceSuccessMessage("Offline Attendance Recorded");
      setMemberCardNumber("");
      setSelectedEntryType("");
      setPriceOfEvent(0);
      setTimeout(() => {
        setRecordAttendenceSuccessMessage("");
      }, 3000);
    });
  };

  return (
    <div>
      <Drawer
        PaperProps={{
          sx: {
            width: "30%",
            height: "100vh",
          },
        }}
        anchor="right"
        open={recordAttendence}
        onClose={() => setRecordAttendence(false)}
      >
        {!recordAttendanceSuccess ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                m: "2rem",
              }}
            >
              <Typography>
                <IconButton onClick={() => setRecordAttendence(false)}>
                  <KeyboardBackspaceOutlinedIcon />
                </IconButton>
                {t("Record Attendance ")}
                Offline
              </Typography>
              {recordAttendenceSuccessMessage ? (
                <h6
                  className={
                    recordAttendenceSuccessMessage
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  {recordAttendenceSuccessMessage
                    ? recordAttendenceSuccessMessage + " !"
                    : ""}
                </h6>
              ) : (
                ""
              )}
              {attendanceError ? (
                <h6 className="text-danger mb-2">{attendanceError}</h6>
              ) : (
                ""
              )}
              <TextField
                sx={{ width: "100%", mb: 0 }}
                size="small"
                label={t("Member Card Number")}
                value={memberCardNumber}
                onChange={(e) => setMemberCardNumber(e.target.value)}
              />
              {memberCardNumberError ? (
                <Typography
                  variant="paragraph"
                  sx={{ fontSize: "14px", color: "red" }}
                >
                  {memberCardNumberError}
                </Typography>
              ) : null}
              {!memberFoundStatus ? null : (
                <>
                  <Typography
                    variant="paragraph"
                    sx={{ fontSize: "14px", color: "red" }}
                  >
                    {memberFoundMessage}
                  </Typography>
                </>
              )}
              <FormControl sx={{ width: "100%" }} size="small">
                <InputLabel>{t("Entry Type")}</InputLabel>
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 40 * 4.5 + 4,
                      },
                    },
                  }}
                  label={t("Entry Type")}
                  value={selectedEntryType}
                  onChange={(e) => setSelectedEntryType(e.target.value)}
                >
                  {entryType?.map((entryTypeItem) => (
                    <MenuItem value={entryTypeItem.entryTypeID}>
                      {entryTypeItem.entryTypeName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {entryTypeError ? (
                <Typography
                  variant="paragraph"
                  sx={{ fontSize: "14px", color: "red" }}
                >
                  {entryTypeError}
                </Typography>
              ) : null}
              <FormControl sx={{ width: "100%" }} size="small">
                <InputLabel>{t("Payment Mode")}</InputLabel>
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 40 * 4.5 + 4,
                      },
                    },
                  }}
                  label={t("Payment Mode")}
                  value={paymentMode}
                  onChange={(e) => setPaymentMode(e.target.value)}
                >
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="MyPoS" selected>
                    MyPoS
                  </MenuItem>
                  <MenuItem value="CreditCard">CreditCard</MenuItem>
                  <MenuItem value="DebitCard">DebitCard</MenuItem>
                </Select>
              </FormControl>
              {paymentModeError ? (
                <Typography
                  variant="paragraph"
                  sx={{ fontSize: "14px", color: "red" }}
                >
                  {paymentModeError}
                </Typography>
              ) : null}
              {priceOfEvent ? (
                <Button
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    color: "orange",
                    m: "auto",
                    p: "0.5rem 0.5rem",
                    backgroundColor: "lightgrey",
                    "&:hover": {
                      backgroundColor: "lightgrey",
                      color: "orange",
                    },
                  }}
                >
                  {t("Pending Amount")}:&nbsp;&nbsp;
                  <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                    {currencySymbol}
                  </span>
                  &nbsp;
                  {priceOfEvent}
                </Button>
              ) : (
                <Button
                  sx={{
                    m: "auto",
                    width: "100%",
                    backgroundColor: "lightgray",
                    color: paymentPaid ? "green" : "red",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "lightgray",
                    },
                  }}
                >
                  {paymentPaid ? t("Payment Paid") : t("Pending Amount")}
                  {!paymentPaid ? ` : ${currencySymbol} 0` : ""}
                </Button>
              )}
            </Box>
            <Button
              sx={{
                textTransform: "none",
                width: "90%",
                backgroundColor: "orange",
                m: "auto",
                bottom: "1rem",
                color: "#fff",
                p: "0.5rem 0.5rem",
                "&:hover": {
                  backgroundColor: "orange",
                },
              }}
              onClick={handleCreateAttendance}
            >
              {t("Submit")}
            </Button>
          </>
        ) : null}
      </Drawer>
    </div>
  );
};

export default RecordAttendanceOffline;

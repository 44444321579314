import "../../App.css";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "react-bootstrap";
import DatePicker from "react-date-picker";

function EditMember() {
  const [model, setModel] = useState(true);

  const [, setEmail] = useState();
  const [, setCardNumber] = useState();
  const [, setFirstName] = useState();
  const [, setLasttName] = useState();
  const [, setCountryCode] = useState();
  const [, setMobileNumber] = useState();
  const [dob, setDob] = useState(new Date());
  const [, setGender] = useState();
  const [, setAddress] = useState();
  const [isRequired, setIsRequired] = useState();
  const [isValidate, setIsValidate] = useState();

  const handleModal = () => {
    if (!model) {
      setModel(true);
    } else {
      setModel(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsRequired(false);
    setIsValidate(false);
  };

  return (
    <div>
      <Modal show={model} onHide={handleModal}>
        <Modal.Body>
          <div className="login d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 col-xl-11 mx-auto">
                  <form onSubmit={handleSubmit}>
                    <h4>Edit Member</h4>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        id="memberCardNumber"
                        className="form-control"
                        onChange={(e) => setCardNumber(e.target.value)}
                        placeholder="Member Card Number"
                        data-1p-ignore="true"
                      />
                      <label htmlFor="floatingInput">Member Card Number</label>
                      {isValidate && (
                        <div className="mb-2">
                          <span className="text-danger mb-2">
                            Entered Member Card Number doesn't exist!
                          </span>
                        </div>
                      )}
                      {isRequired && (
                        <div className="mb-2">
                          <span className="text-danger">
                            Member Card Number is required
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="First Name"
                      />
                      <label htmlFor="floatingInput">First Name</label>
                      {isValidate && (
                        <div className="mb-2">
                          <span className="text-danger mb-2">
                            First Name doesn't exist!
                          </span>
                        </div>
                      )}
                      {isRequired && (
                        <div className="mb-2">
                          <span className="text-danger">
                            First Name is required
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setLasttName(e.target.value)}
                        placeholder="Last Name"
                      />
                      <label htmlFor="floatingInput">Last Name</label>
                      {isRequired && (
                        <div className="mb-2">
                          <span className="text-danger">
                            Last Name is required
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                      />
                      <label htmlFor="floatingInput">Email</label>
                      {isValidate && (
                        <div className="mb-2">
                          <span className="text-danger mb-2">
                            Email doesn't exist!
                          </span>
                        </div>
                      )}
                      {isRequired && (
                        <div className="mb-2">
                          <span className="text-danger">Email is required</span>
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        onChange={(e) => setCountryCode(e.target.value)}
                        placeholder="Country Code"
                        className="form-control mb-2 mt-2"
                      />
                      <label htmlFor="floatingInput">Country Code</label>
                      {isRequired && (
                        <div className="mb-2">
                          <span className="text-danger">
                            Country Code is required
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        onChange={(e) => setMobileNumber(e.target.value)}
                        placeholder="Mobile Number"
                        className="form-control mb-2 mt-2"
                      />
                      <label htmlFor="floatingInput">Mobile Number</label>

                      {isRequired && (
                        <div className="mb-2">
                          <span className="text-danger">
                            Mobile Number is required
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setDob(e.target.value)}
                        placeholder="Date of Birth"
                      />
                      <DatePicker
                        className="form-control mt-1"
                        onChange={(date) => setDob(date)}
                        value={dob}
                      />
                      <label htmlFor="floatingInput">Date of Birth</label>
                      {isRequired && (
                        <div className="mb-2">
                          <span className="text-danger">
                            Date of Birth is required
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setGender(e.target.value)}
                        placeholder="Gender"
                      />
                      <label htmlFor="floatingInput">Gender</label>
                      {isRequired && (
                        <div className="mb-2">
                          <span className="text-danger">
                            Gender is required
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Address"
                      />
                      <label htmlFor="floatingInput">Address</label>
                      {isRequired && (
                        <div className="mb-2">
                          <span className="text-danger">
                            Address is required
                          </span>
                        </div>
                      )}
                    </div>

                    <button
                      type="submit"
                      className="btn background-orange form-control mt-3 text-white"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default EditMember;
